import React from 'react';
import { AutoWidgetWrapper } from '../common';

const MonthlyCalendar = () => (
  <AutoWidgetWrapper title="Monthly Calendar">
    <div className="h-64 bg-[var(--color-highlight)] rounded-md flex items-center justify-center text-[var(--color-secondary)]">
      Calendar Placeholder
    </div>
  </AutoWidgetWrapper>
);

export default MonthlyCalendar;
