// src/components/widgets/ClaimsSection.js

import React, { useState, useEffect } from 'react';
import { getOutstandingClaimsData } from '../../data/dataServices';

export default function ClaimsSection() {
  const [claims, setClaims] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState(null);

  useEffect(() => {
    async function fetchClaims() {
      const data = await getOutstandingClaimsData();
      setClaims(data);
    }
    fetchClaims();
  }, []);

  const closeModal = () => setSelectedClaim(null);

  return (
    <div className="bg-[var(--color-background)] shadow rounded-lg p-4">
      <h3 className="text-xl font-semibold text-[var(--color-text)]">Outstanding Claims</h3>
      {claims.map((claim, index) => (
        <div 
          key={index} 
          className="flex justify-between p-2 border-b border-[var(--color-secondary)] hover:bg-[var(--color-highlight)] transition cursor-pointer"
          onClick={() => setSelectedClaim(claim)}
        >
          <div>
            <h4 className="text-lg font-medium text-[var(--color-text)]">{claim.payor}</h4>
            <p className="text-sm text-[var(--color-secondary)]">Amount: ${claim.amount.toLocaleString()}</p>
            <p className="text-sm text-[var(--color-secondary)]">Avg Days Outstanding: {claim.avgDaysOutstanding}</p>
          </div>
        </div>
      ))}

      {/* Drill-Down Modal */}
      {selectedClaim && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-lg">
            <h3 className="text-2xl font-bold mb-4">Claim Details for {selectedClaim.payor}</h3>
            <p>Amount: ${selectedClaim.amount}</p>
            <p>Avg Days Outstanding: {selectedClaim.avgDaysOutstanding}</p>
            <p>More details on the claim status...</p>
            <button className="mt-4 px-4 py-2 bg-[var(--color-primary)] text-white rounded" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
