export const patients = [
  {
    id: 1,
    lastName: "Brown",
    firstName: "Alice",
    dateOfBirth: "1989-05-15",
    age: 34,
    gender: "Female",
    phoneNumber: "555-123-4567",
    email: "alice.brown@example.com",
    address: "123 Oak St, Anytown, USA",
    lastEncounter: "2023-05-01",
    nextAppointment: "2023-06-15",
    outstandingOrders: 2,
    overdueCare: 1,
    image: "https://i.pravatar.cc/150?img=1",
    medications: ["Lisinopril", "Metformin"],
    allergies: ["Penicillin"],
    conditions: ["Hypertension", "Type 2 Diabetes"],
    vitalSigns: {
      height: "5'6\"",
      weight: "150 lbs",
      bmi: 24.2,
      bloodPressure: "128/82",
      heartRate: 72,
      temperature: "98.6°F"
    },
    labResults: [
      { name: "HbA1c", value: 6.8, unit: "%", date: "2023-04-15", normalRange: "4.0-5.6%" },
      { name: "LDL Cholesterol", value: 110, unit: "mg/dL", date: "2023-04-15", normalRange: "<100 mg/dL" },
      { name: "Blood Pressure", value: "128/82", unit: "mmHg", date: "2023-04-15", normalRange: "<120/80 mmHg" }
    ],
    lastVisit: "2023-05-01"
  },
  {
    id: 2,
    lastName: "Baker",
    firstName: "Bob",
    dateOfBirth: "1982-09-22",
    age: 41,
    gender: "Male",
    phoneNumber: "555-234-5678",
    email: "bob.baker@example.com",
    address: "456 Pine St, Somewhere, USA",
    lastEncounter: "2023-03-15",
    nextAppointment: "2023-06-15",
    outstandingOrders: 0,
    overdueCare: 0,
    image: "https://i.pravatar.cc/150?img=3",
    medications: ["Atorvastatin"],
    allergies: [],
    conditions: ["High Cholesterol"],
    vitalSigns: {
      height: "5'10\"",
      weight: "180 lbs",
      bmi: 25.8,
      bloodPressure: "120/80",
      heartRate: 68,
      temperature: "98.4°F"
    },
    labResults: [
      { name: "Total Cholesterol", value: 210, unit: "mg/dL", date: "2023-03-01", normalRange: "<200 mg/dL" },
      { name: "HDL Cholesterol", value: 45, unit: "mg/dL", date: "2023-03-01", normalRange: ">40 mg/dL" },
      { name: "LDL Cholesterol", value: 130, unit: "mg/dL", date: "2023-03-01", normalRange: "<100 mg/dL" }
    ],
    lastVisit: "2023-03-15"
  },
  {
    id: 3,
    lastName: "Benson",
    firstName: "Carol",
    dateOfBirth: "1996-03-10",
    age: 27,
    gender: "Female",
    phoneNumber: "555-345-6789",
    email: "carol.benson@example.com",
    address: "789 Maple St, Elsewhere, USA",
    lastEncounter: "2023-05-20",
    nextAppointment: "2023-06-16",
    outstandingOrders: 1,
    overdueCare: 0,
    image: "https://i.pravatar.cc/150?img=5",
    medications: ["Sertraline"],
    allergies: ["Sulfa drugs"],
    conditions: ["Anxiety"],
    vitalSigns: {
      height: "5'4\"",
      weight: "130 lbs",
      bmi: 22.3,
      bloodPressure: "118/76",
      heartRate: 76,
      temperature: "98.2°F"
    },
    labResults: [
      { name: "TSH", value: 2.5, unit: "mIU/L", date: "2023-05-10", normalRange: "0.4-4.0 mIU/L" },
      { name: "Vitamin D", value: 32, unit: "ng/mL", date: "2023-05-10", normalRange: "20-50 ng/mL" },
      { name: "Blood Pressure", value: "118/76", unit: "mmHg", date: "2023-05-10", normalRange: "<120/80 mmHg" }
    ],
    lastVisit: "2023-05-20"
  },
  {
    id: 4,
    lastName: "Blackwell",
    firstName: "David",
    dateOfBirth: "1969-11-05",
    age: 54,
    gender: "Male",
    phoneNumber: "555-456-7890",
    email: "david.blackwell@example.com",
    address: "101 Cedar St, Nowhere, USA",
    lastEncounter: "2023-02-10",
    nextAppointment: "2023-06-17",
    outstandingOrders: 3,
    overdueCare: 2,
    image: "https://i.pravatar.cc/150?img=7",
    medications: ["Metoprolol", "Aspirin"],
    allergies: ["Latex"],
    conditions: ["Coronary Artery Disease", "Hypertension"],
    vitalSigns: {
      height: "5'11\"",
      weight: "200 lbs",
      bmi: 27.9,
      bloodPressure: "132/84",
      heartRate: 64,
      temperature: "98.8°F"
    },
    labResults: [
      { name: "Troponin", value: 0.02, unit: "ng/mL", date: "2023-02-05", normalRange: "<0.04 ng/mL" },
      { name: "LDL Cholesterol", value: 95, unit: "mg/dL", date: "2023-02-05", normalRange: "<100 mg/dL" },
      { name: "Blood Pressure", value: "132/84", unit: "mmHg", date: "2023-02-05", normalRange: "<120/80 mmHg" }
    ],
    lastVisit: "2023-02-10"
  },
  {
    id: 5,
    lastName: "Bradshaw",
    firstName: "Emma",
    dateOfBirth: "1993-07-28",
    age: 30,
    gender: "Female",
    phoneNumber: "555-567-8901",
    email: "emma.bradshaw@example.com",
    address: "202 Birch St, Anywhere, USA",
    lastEncounter: "2023-05-25",
    nextAppointment: "2023-06-18",
    outstandingOrders: 0,
    overdueCare: 0,
    image: "https://i.pravatar.cc/150?img=9",
    medications: [],
    allergies: ["Peanuts"],
    conditions: [],
    vitalSigns: {
      height: "5'7\"",
      weight: "140 lbs",
      bmi: 21.9,
      bloodPressure: "110/70",
      heartRate: 68,
      temperature: "98.4°F"
    },
    labResults: [
      { name: "CBC", value: "Within normal limits", unit: "", date: "2023-05-20", normalRange: "N/A" },
      { name: "Ferritin", value: 48, unit: "ng/mL", date: "2023-05-20", normalRange: "11-307 ng/mL" },
      { name: "Blood Pressure", value: "110/70", unit: "mmHg", date: "2023-05-20", normalRange: "<120/80 mmHg" }
    ],
    lastVisit: "2023-05-25"
  },
  {
    id: 6,
    lastName: "Davis",
    firstName: "Emily",
    dateOfBirth: "2019-03-15",
    age: 4,
    gender: "Female",
    phoneNumber: "555-678-9012",
    email: "emily.davis@example.com",
    address: "303 Elm St, Somewhere, USA",
    lastEncounter: "2023-03-20",
    nextAppointment: "2023-06-15",
    outstandingOrders: 0,
    overdueCare: 0,
    image: "https://i.pravatar.cc/150?img=11",
    medications: [],
    allergies: ["Eggs"],
    conditions: [],
    vitalSigns: {
      height: "3'2\"",
      weight: "35 lbs",
      bmi: 16.5,
      bloodPressure: "90/60",
      heartRate: 100,
      temperature: "98.6°F"
    },
    labResults: [
      { name: "Lead Level", value: 2, unit: "μg/dL", date: "2023-03-15", normalRange: "<5 μg/dL" },
      { name: "Hemoglobin", value: 12.5, unit: "g/dL", date: "2023-03-15", normalRange: "11.0-14.0 g/dL" },
      { name: "Blood Pressure", value: "90/60", unit: "mmHg", date: "2023-03-15", normalRange: "Age-specific" }
    ],
    lastVisit: "2023-03-20"
  },
  {
    id: 7,
    lastName: "Wilson",
    firstName: "Robert",
    dateOfBirth: "1971-08-03",
    age: 52,
    gender: "Male",
    phoneNumber: "555-789-0123",
    email: "robert.wilson@example.com",
    address: "404 Pine St, Elsewhere, USA",
    lastEncounter: "2023-05-10",
    nextAppointment: "2023-06-15",
    outstandingOrders: 1,
    overdueCare: 0,
    image: "https://i.pravatar.cc/150?img=13",
    medications: ["Atorvastatin", "Aspirin"],
    allergies: ["Shellfish"],
    conditions: ["High Blood Pressure"],
    vitalSigns: {
      height: "6'0\"",
      weight: "190 lbs",
      bmi: 25.8,
      bloodPressure: "138/88",
      heartRate: 72,
      temperature: "98.4°F"
    },
    labResults: [
      { name: "Total Cholesterol", value: 195, unit: "mg/dL", date: "2023-05-05", normalRange: "<200 mg/dL" },
      { name: "A1C", value: 5.6, unit: "%", date: "2023-05-05", normalRange: "<5.7%" },
      { name: "Blood Pressure", value: "138/88", unit: "mmHg", date: "2023-05-05", normalRange: "<120/80 mmHg" }
    ],
    lastVisit: "2023-05-10"
  },
  {
    id: 8,
    lastName: "Anderson",
    firstName: "Grace",
    dateOfBirth: "1988-12-10",
    age: 34,
    gender: "Female",
    phoneNumber: "555-234-5678",
    email: "grace.anderson@example.com",
    address: "505 Oak St, Somewhere, USA",
    lastEncounter: "2023-05-15",
    nextAppointment: "2023-06-20",
    outstandingOrders: 1,
    overdueCare: 0,
    image: "https://i.pravatar.cc/150?img=15",
    medications: ["Levothyroxine"],
    allergies: ["Iodine"],
    conditions: ["Hypothyroidism"],
    vitalSigns: {
      height: "5'5\"",
      weight: "135 lbs",
      bmi: 22.5,
      bloodPressure: "118/75",
      heartRate: 68,
      temperature: "98.2°F"
    },
    labResults: [
      { name: "TSH", value: 3.5, unit: "mIU/L", date: "2023-05-10", normalRange: "0.4-4.0 mIU/L" },
      { name: "Free T4", value: 1.2, unit: "ng/dL", date: "2023-05-10", normalRange: "0.8-1.8 ng/dL" },
      { name: "Blood Pressure", value: "118/75", unit: "mmHg", date: "2023-05-10", normalRange: "<120/80 mmHg" }
    ],
    lastVisit: "2023-05-15"
  },
  {
    id: 9,
    lastName: "Chen",
    firstName: "Michael",
    dateOfBirth: "1976-09-18",
    age: 46,
    gender: "Male",
    phoneNumber: "555-345-6789",
    email: "michael.chen@example.com",
    address: "606 Maple St, Elsewhere, USA",
    lastEncounter: "2023-04-28",
    nextAppointment: "2023-06-22",
    outstandingOrders: 0,
    overdueCare: 1,
    image: "https://i.pravatar.cc/150?img=17",
    medications: ["Metformin", "Gliclazide"],
    allergies: ["Sulfa drugs"],
    conditions: ["Type 2 Diabetes", "Hypertension"],
    vitalSigns: {
      height: "5'9\"",
      weight: "180 lbs",
      bmi: 26.6,
      bloodPressure: "135/85",
      heartRate: 76,
      temperature: "98.4°F"
    },
    labResults: [
      { name: "HbA1c", value: 7.2, unit: "%", date: "2023-04-20", normalRange: "4.0-5.6%" },
      { name: "Fasting Glucose", value: 135, unit: "mg/dL", date: "2023-04-20", normalRange: "70-100 mg/dL" },
      { name: "Blood Pressure", value: "135/85", unit: "mmHg", date: "2023-04-20", normalRange: "<120/80 mmHg" },
      ],
      lastVisit: "2023-04-28"
    },
    {
      id: 10,
      lastName: "Evans",
      firstName: "Olivia",
      dateOfBirth: "1995-05-22",
      age: 28,
      gender: "Female",
      phoneNumber: "555-456-7890",
      email: "olivia.evans@example.com",
      address: "707 Pine St, Anywhere, USA",
      lastEncounter: "2023-05-30",
      nextAppointment: "2023-06-25",
      outstandingOrders: 2,
      overdueCare: 0,
      image: "https://i.pravatar.cc/150?img=19",
      medications: ["Fluoxetine"],
      allergies: ["Penicillin"],
      conditions: ["Depression", "Anxiety"],
      vitalSigns: {
        height: "5'6\"",
        weight: "130 lbs",
        bmi: 21.0,
        bloodPressure: "110/70",
        heartRate: 72,
        temperature: "98.6°F"
      },
      labResults: [
        { name: "CBC", value: "Within normal limits", unit: "", date: "2023-05-25", normalRange: "N/A" },
        { name: "TSH", value: 2.5, unit: "mIU/L", date: "2023-05-25", normalRange: "0.4-4.0 mIU/L" },
        { name: "Blood Pressure", value: "110/70", unit: "mmHg", date: "2023-05-25", normalRange: "<120/80 mmHg" }
      ],
      lastVisit: "2023-05-30"
    },
    {
      id: 11,
      lastName: "Garcia",
      firstName: "Sophia",
      dateOfBirth: "1990-01-15",
      age: 33,
      gender: "Female",
      phoneNumber: "555-111-2222",
      email: "sophia.garcia@example.com",
      address: "808 Birch St, Newtown, USA",
      lastEncounter: "2023-05-10",
      nextAppointment: "2023-06-30",
      outstandingOrders: 1,
      overdueCare: 0,
      image: "https://i.pravatar.cc/150?img=21",
      medications: ["Ibuprofen"],
      allergies: ["None"],
      conditions: ["Asthma"],
      vitalSigns: {
        height: "5'3\"",
        weight: "120 lbs",
        bmi: 21.2,
        bloodPressure: "115/75",
        heartRate: 70,
        temperature: "98.6°F"
      },
      labResults: [
        { name: "Allergy Test", value: "Negative", unit: "", date: "2023-05-05", normalRange: "N/A" },
        { name: "Spirometry", value: "FEV1/FVC 80%", unit: "", date: "2023-05-05", normalRange: ">70%" },
        { name: "Blood Pressure", value: "115/75", unit: "mmHg", date: "2023-05-05", normalRange: "<120/80 mmHg" }
      ],
      lastVisit: "2023-05-10"
    },
    {
      id: 12,
      lastName: "Martinez",
      firstName: "Liam",
      dateOfBirth: "1985-04-20",
      age: 38,
      gender: "Male",
      phoneNumber: "555-222-3333",
      email: "liam.martinez@example.com",
      address: "909 Cedar St, Oldtown, USA",
      lastEncounter: "2023-05-15",
      nextAppointment: "2023-07-01",
      outstandingOrders: 0,
      overdueCare: 0,
      image: "https://i.pravatar.cc/150?img=23",
      medications: ["Amlodipine"],
      allergies: ["None"],
      conditions: ["Hypertension"],
      vitalSigns: {
        height: "5'9\"",
        weight: "175 lbs",
        bmi: 25.8,
        bloodPressure: "130/85",
        heartRate: 75,
        temperature: "98.4°F"
      },
      labResults: [
        { name: "Cholesterol", value: 190, unit: "mg/dL", date: "2023-05-10", normalRange: "<200 mg/dL" },
        { name: "Blood Pressure", value: "130/85", unit: "mmHg", date: "2023-05-10", normalRange: "<120/80 mmHg" },
        { name: "Potassium", value: 4.0, unit: "mEq/L", date: "2023-05-10", normalRange: "3.5-5.0 mEq/L" }
      ],
      lastVisit: "2023-05-15"
    },
    {
      id: 13,
      lastName: "Johnson",
      firstName: "Emma",
      dateOfBirth: "1992-11-30",
      age: 30,
      gender: "Female",
      phoneNumber: "555-333-4444",
      email: "emma.johnson@example.com",
      address: "1010 Elm St, Hometown, USA",
      lastEncounter: "2023-05-20",
      nextAppointment: "2023-07-05",
      outstandingOrders: 0,
      overdueCare: 1,
      image: "https://i.pravatar.cc/150?img=25",
      medications: ["Metformin"],
      allergies: ["None"],
      conditions: ["Type 2 Diabetes"],
      vitalSigns: {
        height: "5'4\"",
        weight: "140 lbs",
        bmi: 24.0,
        bloodPressure: "120/80",
        heartRate: 72,
        temperature: "98.6°F"
      },
      labResults: [
        { name: "HbA1c", value: 6.5, unit: "%", date: "2023-05-15", normalRange: "4.0-5.6%" },
        { name: "Fasting Glucose", value: 120, unit: "mg/dL", date: "2023-05-15", normalRange: "70-100 mg/dL" },
        { name: "Blood Pressure", value: "120/80", unit: "mmHg", date: "2023-05-15", normalRange: "<120/80 mmHg" }
      ],
      lastVisit: "2023-05-20"
    },
    {
      id: 14,
      lastName: "Lee",
      firstName: "James",
      dateOfBirth: "1980-07-22",
      age: 43,
      gender: "Male",
      phoneNumber: "555-444-5555",
      email: "james.lee@example.com",
      address: "1111 Oak St, Citytown, USA",
      lastEncounter: "2023-05-25",
      nextAppointment: "2023-07-10",
      outstandingOrders: 0,
      overdueCare: 0,
      image: "https://i.pravatar.cc/150?img=27",
      medications: ["Simvastatin"],
      allergies: ["None"],
      conditions: ["High Cholesterol"],
      vitalSigns: {
        height: "5'11\"",
        weight: "190 lbs",
        bmi: 26.5,
        bloodPressure: "125/80",
        heartRate: 70,
        temperature: "98.4°F"
      },
      labResults: [
        { name: "Total Cholesterol", value: 180, unit: "mg/dL", date: "2023-05-20", normalRange: "<200 mg/dL" },
        { name: "LDL Cholesterol", value: 100, unit: "mg/dL", date: "2023-05-20", normalRange: "<100 mg/dL" },
        { name: "Blood Pressure", value: "125/80", unit: "mmHg", date: "2023-05-20", normalRange: "<120/80 mmHg" }
      ],
      lastVisit: "2023-05-25"
    },
    {
      id: 15,
      lastName: "Taylor",
      firstName: "Isabella",
      dateOfBirth: "1994-02-14",
      age: 29,
      gender: "Female",
      phoneNumber: "555-555-6666",
      email: "isabella.taylor@example.com",
      address: "1212 Pine St, Villagetown, USA",
      lastEncounter: "2023-05-30",
      nextAppointment: "2023-07-15",
      outstandingOrders: 1,
      overdueCare: 0,
      image: "https://i.pravatar.cc/150?img=29",
      medications: ["Levothyroxine"],
      allergies: ["None"],
      conditions: ["Hypothyroidism"],
      vitalSigns: {
        height: "5'5\"",
        weight: "130 lbs",
        bmi: 21.5,
        bloodPressure: "118/76",
        heartRate: 68,
        temperature: "98.2°F"
      },
      labResults: [
        { name: "TSH", value: 2.0, unit: "mIU/L", date: "2023-05-25", normalRange: "0.4-4.0 mIU/L" },
        { name: "Free T4", value: 1.3, unit: "ng/dL", date: "2023-05-25", normalRange: "0.8-1.8 ng/dL" },
        { name: "Blood Pressure", value: "118/76", unit: "mmHg", date: "2023-05-25", normalRange: "<120/80 mmHg" }
      ],
      lastVisit: "2023-05-30"
    },
    {
      id: 16,
      lastName: "Hernandez",
      firstName: "Mia",
      dateOfBirth: "1987-03-30",
      age: 36,
      gender: "Female",
      phoneNumber: "555-666-7777",
      email: "mia.hernandez@example.com",
      address: "1313 Maple St, Townsville, USA",
      lastEncounter: "2023-05-28",
      nextAppointment: "2023-07-20",
      outstandingOrders: 0,
      overdueCare: 0,
      image: "https://i.pravatar.cc/150?img=31",
      medications: ["Albuterol"],
      allergies: ["None"],
      conditions: ["Asthma"],
      vitalSigns: {
        height: "5'6\"",
        weight: "135 lbs",
        bmi: 22.0,
        bloodPressure: "120/75",
        heartRate: 72,
        temperature: "98.6°F"
      },
      labResults: [
        { name: "Spirometry", value: "FEV1/FVC 85%", unit: "", date: "2023-05-20", normalRange: ">70%" },
        { name: "Peak Flow", value: 400, unit: "L/min", date: "2023-05-20", normalRange: "380-600 L/min" },
        { name: "Blood Pressure", value: "120/75", unit: "mmHg", date: "2023-05-20", normalRange: "<120/80 mmHg" }
      ],
      lastVisit: "2023-05-28"
    },
    {
      id: 17,
      lastName: "Lopez",
      firstName: "Ethan",
      dateOfBirth: "1991-08-05",
      age: 32,
      gender: "Male",
      phoneNumber: "555-777-8888",
      email: "ethan.lopez@example.com",
      address: "1414 Oak St, Cityville, USA",
      lastEncounter: "2023-05-29",
      nextAppointment: "2023-07-25",
      outstandingOrders: 0,
      overdueCare: 1,
      image: "https://i.pravatar.cc/150?img=33",
      medications: ["Metformin"],
      allergies: ["None"],
      conditions: ["Type 2 Diabetes"],
      vitalSigns: {
        height: "5'10\"",
        weight: "180 lbs",
        bmi: 25.9,
        bloodPressure: "130/85",
        heartRate: 75,
        temperature: "98.4°F"
      },
      labResults: [
        { name: "HbA1c", value: 6.8, unit: "%", date: "2023-05-25", normalRange: "4.0-5.6%" },
        { name: "Fasting Glucose", value: 130, unit: "mg/dL", date: "2023-05-25", normalRange: "70-100 mg/dL" },
        { name: "Blood Pressure", value: "130/85", unit: "mmHg", date: "2023-05-25", normalRange: "<120/80 mmHg" }
      ],
      lastVisit: "2023-05-29"
    },
    {
      id: 18,
      lastName: "Nguyen",
      firstName: "Ava",
      dateOfBirth: "1993-12-12",
      age: 29,
      gender: "Female",
      phoneNumber: "555-888-9999",
      email: "ava.nguyen@example.com",
      address: "1515 Birch St, Villagetown, USA",
      lastEncounter: "2023-05-30",
      nextAppointment: "2023-07-30",
      outstandingOrders: 0,
      overdueCare: 0,
      image: "https://i.pravatar.cc/150?img=35",
      medications: ["Levothyroxine"],
      allergies: ["None"],
      conditions: ["Hypothyroidism"],
      vitalSigns: {
        height: "5'4\"",
        weight: "125 lbs",
        bmi: 21.5,
        bloodPressure: "115/75",
        heartRate: 70,
        temperature: "98.6°F"
      },
      labResults: [
        { name: "TSH", value: 2.5, unit: "mIU/L", date: "2023-05-25", normalRange: "0.4-4.0 mIU/L" },
        { name: "Free T4", value: 1.2, unit: "ng/dL", date: "2023-05-25", normalRange: "0.8-1.8 ng/dL" },
        { name: "Blood Pressure", value: "115/75", unit: "mmHg", date: "2023-05-25", normalRange: "<120/80 mmHg" }
      ],
      lastVisit: "2023-05-30"
  },
  {
    id: 19,
    lastName: "Patel",
    firstName: "Noah",
    dateOfBirth: "1988-05-20",
    age: 35,
    gender: "Male",
    phoneNumber: "555-999-0000",
    email: "noah.patel@example.com",
    address: "1616 Cedar St, Townsville, USA",
    lastEncounter: "2023-05-30",
    nextAppointment: "2023-08-01",
    outstandingOrders: 0,
    overdueCare: 0,
    image: "https://i.pravatar.cc/150?img=37",
    medications: ["Simvastatin"],
    allergies: ["None"],
    conditions: ["High Cholesterol"],
    vitalSigns: {
      height: "5'11\"",
      weight: "185 lbs",
      bmi: 26.0,
      bloodPressure: "125/80",
      heartRate: 72,
      temperature: "98.4°F"
    },
    labResults: [
      { name: "Total Cholesterol", value: 190, unit: "mg/dL", date: "2023-05-30", normalRange: "<200 mg/dL" },
      { name: "LDL Cholesterol", value: 110, unit: "mg/dL", date: "2023-05-30", normalRange: "<100 mg/dL" },
      { name: "Blood Pressure", value: "125/80", unit: "mmHg", date: "2023-05-30", normalRange: "<120/80 mmHg" }
    ],
    lastVisit: "2023-05-30"
  },
  {
    id: 20,
    lastName: "Kim",
    firstName: "Lily",
    dateOfBirth: "1995-09-15",
    age: 28,
    gender: "Female",
    phoneNumber: "555-111-2222",
    email: "lily.kim@example.com",
    address: "1717 Maple St, Citytown, USA",
    lastEncounter: "2023-05-30",
    nextAppointment: "2023-08-05",
    outstandingOrders: 0,
    overdueCare: 0,
    image: "https://i.pravatar.cc/150?img=39",
    medications: ["Ibuprofen"],
    allergies: ["None"],
    conditions: ["Asthma"],
    vitalSigns: {
      height: "5'3\"",
      weight: "120 lbs",
      bmi: 21.2,
      bloodPressure: "115/75",
      heartRate: 70,
      temperature: "98.6°F"
    },
    labResults: [
      { name: "Spirometry", value: "FEV1/FVC 82%", unit: "", date: "2023-05-30", normalRange: ">70%" },
      { name: "Peak Flow", value: 380, unit: "L/min", date: "2023-05-30", normalRange: "380-600 L/min" },
      { name: "Blood Pressure", value: "115/75", unit: "mmHg", date: "2023-05-30", normalRange: "<120/80 mmHg" }
    ],
    lastVisit: "2023-05-30"
  }
];

export const appointments = [
  {
    id: 1,
    patientId: 1,
    date: new Date().toISOString().split('T')[0], // Today's date
    time: "9:00 AM",
    reason: "Follow-up",
    notes: "Review medication efficacy",
    patient: patients.find(p => p.id === 1)
  },
  {
    id: 2,
    patientId: 2,
    date: new Date().toISOString().split('T')[0], // Today's date
    time: "10:30 AM",
    reason: "Annual physical",
    notes: "Routine check-up",
    patient: patients.find(p => p.id === 2)
  },
  {
    id: 3,
    patientId: 3,
    date: new Date().toISOString().split('T')[0], // Today's date
    time: "11:00 AM",
    reason: "Lab results review",
    notes: "Discuss cholesterol levels",
    patient: patients.find(p => p.id === 3)
  },
  {
    id: 4,
    patientId: 4,
    date: new Date().toISOString().split('T')[0], // Today's date
    time: "11:15 AM",
    reason: "Cardiac follow-up",
    notes: "Evaluate current treatment plan",
    patient: patients.find(p => p.id === 4)
  },
  {
    id: 5,
    patientId: 5,
    date: new Date().toISOString().split('T')[0], // Today's date
    time: "2:00 PM",
    reason: "New patient visit",
    notes: "Initial consultation",
    patient: patients.find(p => p.id === 5)
  },
  {
    id: 6,
    patientId: 6,
    date: new Date().toISOString().split('T')[0], // Today's date
    time: "2:15 PM",
    reason: "Vaccination",
    notes: "Routine vaccination",
    patient: patients.find(p => p.id === 6)
  },
  {
    id: 7,
    patientId: 7,
    date: new Date().toISOString().split('T')[0], // Today's date
    time: "2:30 PM",
    reason: "Consultation",
    notes: "Discuss treatment options",
    patient: patients.find(p => p.id === 7)
  },
  // Appointments for tomorrow start here
  {
    id: 8,
    patientId: 11,
    date: new Date(Date.now() + 86400000).toISOString().split('T')[0], // Tomorrow's date
    time: "3:00 PM",
    reason: "Follow-up",
    notes: "Review asthma management",
    patient: patients.find(p => p.id === 11)
  },
  {
    id: 9,
    patientId: 12,
    date: new Date(Date.now() + 86400000).toISOString().split('T')[0], // Tomorrow's date
    time: "1:00 PM",
    reason: "Annual physical",
    notes: "Routine check-up",
    patient: patients.find(p => p.id === 12)
  },
  {
    id: 10,
    patientId: 13,
    date: new Date(Date.now() + 86400000).toISOString().split('T')[0], // Tomorrow's date
    time: "10:00 AM",
    reason: "Lab results review",
    notes: "Discuss diabetes management",
    patient: patients.find(p => p.id === 13)
  },
  {
    id: 11,
    patientId: 14,
    date: new Date(Date.now() + 86400000).toISOString().split('T')[0], // Tomorrow's date
    time: "11:00 AM",
    reason: "Cardiac follow-up",
    notes: "Evaluate treatment plan",
    patient: patients.find(p => p.id === 14)
  },
  {
    id: 12,
    patientId: 15,
    date: new Date(Date.now() + 86400000).toISOString().split('T')[0], // Tomorrow's date
    time: "2:00 PM",
    reason: "New patient visit",
    notes: "Initial consultation",
    patient: patients.find(p => p.id === 15)
  },
  {
    id: 13,
    patientId: 16,
    date: new Date(Date.now() + 86400000).toISOString().split('T')[0], // Tomorrow's date
    time: "2:15 PM",
    reason: "Vaccination",
    notes: "Routine vaccination",
    patient: patients.find(p => p.id === 16)
  },
  {
    id: 14,
    patientId: 17,
    date: new Date(Date.now() + 86400000).toISOString().split('T')[0], // Tomorrow's date
    time: "2:30 PM",
    reason: "Consultation",
    notes: "Discuss asthma management",
    patient: patients.find(p => p.id === 17)
  }
];

export const messages = [
  {
    id: 1,
    type: 'internal',
    priority: 'high',
    subject: 'Lab Results for Alice Brown',
    content: 'New lab results available for review.',
    from: 'Lab Department',
    to: 'Dr. Smith',
    date: '2023-06-14T10:30:00',
    read: false
  },
  {
    id: 2,
    type: 'external',
    priority: 'medium',
    subject: 'Medication Refill Request',
    content: 'Patient Bob Baker is requesting a refill for Atorvastatin.',
    from: 'Bob Baker',
    to: 'Dr. Smith',
    date: '2023-06-13T14:45:00',
    read: true
  },
  {
    id: 3,
    type: 'external',
    priority: 'low',
    subject: 'EHR Update Notification',
    content: 'The EHR system will be updated tonight at 2 AM. Please save all work before leaving.',
    from: 'IT Department',
    to: 'All Staff',
    date: '2023-06-12T09:00:00',
    read: true
  },
  // New messages
  {
    id: 4,
    type: 'internal',
    priority: 'high',
    subject: 'Lab Results for Sophia Garcia',
    content: 'New lab results available for review.',
    from: 'Lab Department',
    to: 'Dr. Smith',
    date: '2023-06-14T10:30:00',
    read: false
  },
  {
    id: 5,
    type: 'external',
    priority: 'medium',
    subject: 'Medication Refill Request',
    content: 'Patient Liam Martinez is requesting a refill for Amlodipine.',
    from: 'Liam Martinez',
    to: 'Dr. Smith',
    date: '2023-06-13T14:45:00',
    read: true
  },
  {
    id: 6,
    type: 'external',
    priority: 'low',
    subject: 'EHR Update Notification',
    content: 'The EHR system will be updated tonight at 2 AM. Please save all work before leaving.',
    from: 'IT Department',
    to: 'All Staff',
    date: '2023-06-12T09:00:00',
    read: true
  }
];

export const inboxMessages = [
  { sender: 'Dr. Amanda Lee', subject: 'Patient Referral', urgent: true },
  { sender: 'John Doe', subject: 'Medication Question', urgent: false },
  { sender: 'Nurse Mark', subject: 'Lab Results', urgent: true },
  { sender: 'Jane Smith', subject: 'Appointment Request', urgent: false },
  // New inbox messages
  { sender: 'Dr. Sarah Connor', subject: 'Follow-up on Lab Results', urgent: true },
  { sender: 'Nurse Kelly', subject: 'Patient Update', urgent: false },
];

export const labsToReview = [
  { patient: 'George Brown', test: 'CBC', date: '2023-09-15' },
  { patient: 'Emma White', test: 'Lipid Panel', date: '2023-09-16' },
  { patient: 'Oliver Green', test: 'Thyroid Function', date: '2023-09-17' },
  // New labs to review
  { patient: 'Sophia Garcia', test: 'Allergy Test', date: '2023-06-05' },
  { patient: 'Liam Martinez', test: 'Cholesterol', date: '2023-06-10' },
];

export const tasks = [
  { task: 'Update patient care plans', due: 'Today' },
  { task: 'Complete CME course', due: 'Tomorrow' },
  { task: 'Team meeting', due: 'Sep 20' },
  { task: 'Review new treatment guidelines', due: 'Sep 22' },
  // New tasks
  { task: 'Prepare for upcoming audit', due: 'Sep 25' },
  { task: 'Schedule staff training', due: 'Sep 30' },
];

export const openCharts = [
  { patient: 'Alice Johnson', reason: 'Chronic Pain Management', lastUpdated: '1 hour ago' },
  { patient: 'Bob Smith', reason: 'Diabetes Follow-up', lastUpdated: '3 hours ago' },
  { patient: 'Carol Davis', reason: 'Anxiety Treatment', lastUpdated: 'Yesterday' },
  // New open charts
  { patient: 'Sophia Garcia', reason: 'Asthma Management', lastUpdated: '2 hours ago' },
  { patient: 'Liam Martinez', reason: 'Hypertension Follow-up', lastUpdated: '4 hours ago' },
];

export const deniedClaims = [
  { patient: 'David Wilson', date: '2023-09-10', amount: '$450', reason: 'Non-covered service' },
  { patient: 'Eva Brown', date: '2023-09-12', amount: '$275', reason: 'Missing information' },
  { patient: 'Frank Taylor', date: '2023-09-14', amount: '$680', reason: 'Out-of-network provider' },
  // New denied claims
  { patient: 'Sophia Garcia', date: '2023-09-15', amount: '$300', reason: 'Non-covered service' },
  { patient: 'Liam Martinez', date: '2023-09-16', amount: '$150', reason: 'Missing information' },
];

export const userPreferences = {
  dashboardLayout: [
    { i: 'schedule', x: 0, y: 0, w: 6, h: 8 },
    { i: 'inbox', x: 6, y: 0, w: 6, h: 8 },
    { i: 'labs', x: 0, y: 8, w: 4, h: 8 },
    { i: 'tasks', x: 4, y: 8, w: 4, h: 8 },
    { i: 'openCharts', x: 8, y: 8, w: 4, h: 8 },
    { i: 'deniedClaims', x: 0, y: 16, w: 12, h: 8 },
  ]
};


export const cdsRules = [
  {
    id: 1,
    condition: "Type 2 Diabetes",
    check: (patient) => {
      const hba1c = patient.labResults?.find(lab => lab.name === "HbA1c")?.value;
      return hba1c !== undefined && hba1c > 7;
    },
    recommendation: "Consider adjusting diabetes management plan. HbA1c is above target.",
    source: "ADA Guidelines 2023",
    severity: "warning",
  },
  {
    id: 2,
    condition: "Hypertension",
    check: (patient) => {
      const bp = patient.labResults?.find(lab => lab.name === "Blood Pressure")?.value;
      if (!bp) return false;
      const [systolic, diastolic] = bp.split("/").map(Number);
      return systolic > 130 || diastolic > 80;
    },
    recommendation: "Blood pressure is above target. Consider lifestyle modifications or medication adjustment.",
    source: "AHA/ACC Guidelines",
    severity: "warning",
  },
  {
    id: 3,
    condition: "General",
    check: (patient) => {
      if (!patient.lastVisit) return false;
      const lastVisitDate = new Date(patient.lastVisit);
      const currentDate = new Date();
      const monthsSinceLastVisit = (currentDate - lastVisitDate) / (1000 * 60 * 60 * 24 * 30);
      return monthsSinceLastVisit > 6;
    },
    recommendation: "Patient due for follow-up visit. Schedule appointment.",
    source: "Preventive Care Guidelines",
    severity: "info",
  },
  {
    id: 4,
    condition: "General",
    check: (patient) => {
      const ldl = patient.labResults?.find(lab => lab.name === "LDL Cholesterol")?.value;
      return ldl !== undefined && ldl > 100;
    },
    recommendation: "LDL Cholesterol is elevated. Consider statin therapy or lifestyle modifications.",
    source: "ACC/AHA Cholesterol Guidelines",
    severity: "warning",
  },
];

// In CDSWidgetWithPopup.js


// Helper function to get a patient by ID
export const getPatientById = (id) => {
  return patients.find(patient => patient.id === id);
};

// Helper function to get appointments for a specific date
export const getAppointmentsByDate = (date) => {
  const dateString = date instanceof Date ? date.toISOString().split('T')[0] : date;
  return appointments.filter(appointment => appointment.date === dateString);
};

// Helper function to get unread messages
export const getUnreadMessages = () => {
  return messages.filter(message => !message.read);
};

// Update a couple of message types to ensure we have both 'internal' and 'external'
messages[1].type = 'external';
messages[2].type = 'external';

export const practiceData = {
  name: "Hope Valley Family Medicine",
  totalRevenue: 576432.87,
  outstandingClaims: 120567.43,
  averageClaimProcessingTime: 42,
  totalPatients: 3302,
  activePatients: 2897,
  appointmentsThisMonth: 483,
  growthRate: 6.53,
};

export const doctorData = [
  {
    name: "Dr. M. Kahn, MD",
    patientsSeenThisMonth: 322,
    revenueGenerated: 384289.56,
    claimsDenialRate: 3.02,
    totalPatients: 2104,
  },
  {
    name: "Dr. J. Smith, DO",
    patientsSeenThisMonth: 161,
    revenueGenerated: 192143.31,
    claimsDenialRate: 2.48,
    totalPatients: 799,
  },
  // New doctor data
  {
    name: "Dr. A. Brown, MD",
    patientsSeenThisMonth: 200,
    revenueGenerated: 250000.00,
    claimsDenialRate: 1.50,
    totalPatients: 1500,
  },
  {
    name: "Dr. C. White, DO",
    patientsSeenThisMonth: 180,
    revenueGenerated: 220000.00,
    claimsDenialRate: 2.00,
    totalPatients: 1200,
  },
];

export const revenueBreakdownData = [
  { payor: "Blue Cross Blue Shield", revenueReceived: 172345.67, claims: 1723, patients: 689, dollarPerPatient: 250.14, claimDenialRate: 2.8 },
  { payor: "Medicare", revenueReceived: 138765.43, claims: 2312, patients: 925, dollarPerPatient: 150.02, claimDenialRate: 1.5 },
  { payor: "Medicaid", revenueReceived: 86543.21, claims: 1442, patients: 577, dollarPerPatient: 149.99, claimDenialRate: 3.2 },
  { payor: "Harvard Pilgrim Health", revenueReceived: 69876.54, claims: 698, patients: 279, dollarPerPatient: 250.45, claimDenialRate: 2.5 },
  { payor: "Tufts Health Plan", revenueReceived: 57654.32, claims: 576, patients: 230, dollarPerPatient: 250.67, claimDenialRate: 2.7 },
  // New revenue breakdown data
  { payor: "Aetna", revenueReceived: 50000.00, claims: 500, patients: 200, dollarPerPatient: 250.00, claimDenialRate: 2.0 },
  { payor: "Cigna", revenueReceived: 60000.00, claims: 600, patients: 250, dollarPerPatient: 240.00, claimDenialRate: 1.8 },
];

export const outstandingClaimsData = [
  { payor: "Blue Cross Blue Shield", amount: 45678.90, claims: 456, avgDaysOutstanding: 32 },
  { payor: "Medicare", amount: 34567.89, claims: 345, avgDaysOutstanding: 28 },
  { payor: "Medicaid", amount: 23456.78, claims: 234, avgDaysOutstanding: 40 },
  { payor: "Harvard Pilgrim Health", amount: 12345.67, claims: 123, avgDaysOutstanding: 35 },
  { payor: "Tufts Health Plan", amount: 4321.09, claims: 43, avgDaysOutstanding: 30 },
  // New outstanding claims data
  { payor: "Aetna", amount: 15000.00, claims: 150, avgDaysOutstanding: 20 },
  { payor: "Cigna", amount: 20000.00, claims: 200, avgDaysOutstanding: 25 },
  { payor: "UnitedHealthcare", amount: 18000.00, claims: 180, avgDaysOutstanding: 22 }
];


export const userData = {
  currentUser: {
    id: "JB123",
    username: "Dr. J. Bertman",
    firstName: "Jonathan",
    lastName: "Bertman",
    role: "Physician",
    department: "Family Medicine",
    speciality: "Primary Care",
    email: "j.bertman@amazingcharts.com",
    lastLogin: new Date().toISOString(),
    preferences: {
      theme: 'light',
      notifications: true,
      aiAssistant: true,
      dashboardLayout: 'default'
    },
    permissions: {
      admin: true,
      clinicalAccess: true,
      billingAccess: true,
      scheduleAccess: true
    }
  },
  // Add more users as needed
  otherUsers: [
    {
      id: "AL456",
      username: "Dr. A. Lee",
      firstName: "Amanda",
      lastName: "Lee",
      role: "Physician",
      department: "Family Medicine",
      // ... other user data
    },
    // ... other users
  ]
};