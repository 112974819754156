import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { 
  ChevronLeft, 
  ChevronRight, 
  Calendar, 
  MessageSquare, 
  Users, 
  Briefcase, 
  Settings, 
  LayoutGrid,
  GripVertical 
} from 'lucide-react';
import { useGlobalUI } from '../../context/GlobalUIContext';

const defaultNavItems = [
  {
    id: 'dashboard',
    path: '/',
    label: 'Dashboard',
    Icon: LayoutGrid
  },
  {
    id: 'schedule',
    path: '/schedule',
    label: 'Schedule',
    Icon: Calendar
  },
  {
    id: 'messages',
    path: '/messages',
    label: 'Messages',
    Icon: MessageSquare
  },
  {
    id: 'patients',
    path: '/patients',
    label: 'Patients',
    Icon: Users
  },
  {
    id: 'practice',
    path: '/practice-management',
    label: 'Practice Management',
    Icon: Briefcase
  },
  {
    id: 'settings',
    path: '/settings',
    label: 'Settings',
    Icon: Settings
  }
];

export default function Sidebar() {
  const { state, dispatch } = useGlobalUI();
  const location = useLocation();
  const [navItems, setNavItems] = useState(() => {
    const savedOrder = localStorage.getItem('sidebarOrder');
    if (savedOrder) {
      try {
        const savedItems = JSON.parse(savedOrder);
        const isValid = savedItems.every(item => 
          defaultNavItems.find(defaultItem => defaultItem.id === item.id)
        );
        if (isValid) {
          // Merge saved order with default icons
          return savedItems.map(item => ({
            ...item,
            Icon: defaultNavItems.find(def => def.id === item.id).Icon
          }));
        }
      } catch (e) {
        console.error('Error loading saved sidebar order:', e);
      }
    }
    return defaultNavItems;
  });
  
  const [draggedItem, setDraggedItem] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768 && state.isSidebarOpen) {
        dispatch({ type: 'TOGGLE_SIDEBAR' });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, state.isSidebarOpen]);

  useEffect(() => {
    if (window.innerWidth < 768 && state.isSidebarOpen) {
      dispatch({ type: 'TOGGLE_SIDEBAR' });
    }
  }, [location, dispatch, state.isSidebarOpen]);

  useEffect(() => {
    // Store only necessary data in localStorage
    const itemsToStore = navItems.map(({ id, path, label }) => ({ id, path, label }));
    localStorage.setItem('sidebarOrder', JSON.stringify(itemsToStore));
  }, [navItems]);

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
    setIsDragging(true);
    e.target.style.opacity = '0.4';
  };

  const handleDragEnd = (e) => {
    setIsDragging(false);
    setDraggedItem(null);
    e.target.style.opacity = '1';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.style.background = 'var(--color-highlight)';
  };

  const handleDragLeave = (e) => {
    e.currentTarget.style.background = 'transparent';
  };

  const handleDrop = (e, targetItem) => {
    e.preventDefault();
    e.currentTarget.style.background = 'transparent';
    
    if (!draggedItem || draggedItem.id === targetItem.id) return;

    const draggedIndex = navItems.findIndex(item => item.id === draggedItem.id);
    const targetIndex = navItems.findIndex(item => item.id === targetItem.id);
    
    const newNavItems = [...navItems];
    newNavItems.splice(draggedIndex, 1);
    newNavItems.splice(targetIndex, 0, draggedItem);
    
    setNavItems(newNavItems);
  };

  const toggleMenu = () => dispatch({ type: 'TOGGLE_SIDEBAR' });

  const linkStyle = ({ isActive }) => ({
    backgroundColor: isActive ? 'var(--color-highlight)' : 'transparent',
    color: 'var(--color-sidebarText)',
  });

  return (
    <div 
      className={`fixed md:relative transition-all duration-300 h-full z-20 ${
        state.isSidebarOpen ? 'w-64' : 'w-16'
      }`}
      style={{ backgroundColor: 'var(--color-sidebarBackground)' }}
    >
      <div className="flex items-center justify-end p-2">
        <button 
          onClick={toggleMenu} 
          className="p-2 rounded-full hover:bg-[var(--color-highlight)] transition-colors duration-200"
          style={{ color: 'var(--color-sidebarText)' }}
        >
          {state.isSidebarOpen ? (
            <ChevronLeft className="h-5 w-5" />
          ) : (
            <ChevronRight className="h-5 w-5" />
          )}
        </button>
      </div>

      <nav className="mt-2">
        {navItems.map((item) => (
          <div
            key={item.id}
            draggable={state.isSidebarOpen}
            onDragStart={(e) => handleDragStart(e, item)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, item)}
            className={`relative group ${isDragging ? 'cursor-move' : 'cursor-pointer'}`}
          >
            <NavLink
              to={item.path}
              style={linkStyle}
              className="flex items-center p-4 hover:bg-[var(--color-highlight)] transition-colors duration-200"
            >
              <item.Icon className="h-5 w-5 min-w-[20px]" />
              <span className={`ml-2 truncate ${!state.isSidebarOpen ? 'hidden' : 'block'}`}>
                {item.label}
              </span>
              {state.isSidebarOpen && (
                <GripVertical 
                  className="h-5 w-5 opacity-0 group-hover:opacity-100 absolute right-2 cursor-move"
                  style={{ color: 'var(--color-sidebarText)' }}
                />
              )}
            </NavLink>
          </div>
        ))}
      </nav>

      {state.isSidebarOpen && window.innerWidth < 768 && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={toggleMenu}
        />
      )}

      {state.isSidebarOpen && (
        <button
          onClick={() => setNavItems(defaultNavItems)}
          className="absolute bottom-4 left-4 right-4 p-2 rounded-md text-sm"
          style={{ 
            backgroundColor: 'var(--color-highlight)',
            color: 'var(--color-sidebarText)'
          }}
        >
          Reset Default Order
        </button>
      )}
    </div>
  );
}