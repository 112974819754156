import React from 'react';
import { UserPlus, Calendar, MessageSquare, Microscope, FileText, ClipboardList } from 'lucide-react';
import { useGlobalUI } from '../../context/GlobalUIContext';
import { AutoWidgetWrapper } from '../common';

export default function QuickActions() {
  const { addNotification } = useGlobalUI();

  const actions = [
    { 
      icon: UserPlus, 
      label: 'New Patient', 
      action: () => addNotification('Creating new patient...') 
    },
    { 
      icon: Calendar, 
      label: 'Schedule Appointment', 
      action: () => addNotification('Opening scheduler...') 
    },
    { 
      icon: MessageSquare, 
      label: 'New Message', 
      action: () => addNotification('Opening messages...') 
    },
    { 
      icon: Microscope, 
      label: 'Lab Results', 
      action: () => addNotification('Fetching lab results...') 
    },
    { 
      icon: FileText, 
      label: 'Create Note', 
      action: () => addNotification('Creating new note...') 
    },
    { 
      icon: ClipboardList, 
      label: 'New Task', 
      action: () => addNotification('Creating new task...') 
    }
  ];

  return (
    <AutoWidgetWrapper title="Quick Actions">
      <div className="grid grid-cols-2 gap-4">
        {actions.map(({ icon: Icon, label, action }, index) => (
          <button
            key={index}
            onClick={action}
            className="widget-action-button flex items-center justify-start space-x-2 p-2 rounded hover:bg-gray-200 transition"
          >
            <Icon className="h-4 w-4" />
            <span>{label}</span>
          </button>
        ))}
      </div>
    </AutoWidgetWrapper>
  );
}