import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Calendar, HelpCircle, Brain, MessageCircle, Search as SearchIcon,
  Mic, Send, Moon, Sun, Settings, LogOut, Book, Users, MessageSquare, Shield, Lock,
  MessagesSquare,
  MessageSquareIcon
} from 'lucide-react';
import { patients, messages, appointments } from '../../data/mockDatabase';
import amazingChartsLightLogo from '/assets/amazing_charts_light.svg';
import amazingChartsDarkLogo from '/assets/amazing_charts_dark.svg';
import { useTheme } from '../../context/ThemeContext';

export default function TopNavigation({
  toggleChat,
  isAIOpen,
  displayAJReminder,
  handleAJReminderToggle,
  topNavVisibility = {},
  setCurrentView,
}) {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [helpSearchTerm, setHelpSearchTerm] = useState('');
  const navRef = useRef(null);
  const searchInputRef = useRef(null);
  const dropdownRefs = useRef({});
  const [listeningTimeout, setListeningTimeout] = useState(null);

  const { theme, toggleTheme } = useTheme();

  // Use default values if topNavVisibility properties are undefined
  const {
    showSearch = true,
    showCalendarDropdown = true,
    showMessagesDropdown = true,
    showHelp = true,
    showThemeButton = true,
    showUserDetails = true
  } = topNavVisibility;

  const handleDropdownToggle = useCallback((dropdownName) => {
    setOpenDropdown(prev => prev === dropdownName ? null : dropdownName);
  }, []);

  const handleAIAssistantClick = useCallback(() => {
    setOpenDropdown(null);
    toggleChat();
  }, [toggleChat]);

  const startListening = useCallback(() => {
    setIsListening(true);
    const timeout = setTimeout(() => {
      setIsListening(false);
      setSearchTerm(prev => prev + (prev ? ' ' : '') + 'Simulated voice input');
    }, 4000);
    setListeningTimeout(timeout);
  }, []);

  const stopListening = useCallback(() => {
    setIsListening(false);
    if (listeningTimeout) clearTimeout(listeningTimeout);
  }, [listeningTimeout]);

  const handleSearchSubmit = useCallback((e) => {
    e.preventDefault();
    console.log('Searching for:', searchTerm);
    stopListening();
    setSearchTerm('');
    setOpenDropdown(null);
  }, [searchTerm, stopListening]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderDropdown = (name, content) => (
    <div className="relative" ref={el => dropdownRefs.current[name] = el}>
      <button
        className="p-2 hover:bg-opacity-70 flex items-center space-x-2"
        style={{ color: 'var(--color-text)' }}
        onClick={() => handleDropdownToggle(name)}
      >
        {name === 'userMenu' ? (
          <div className="flex items-center space-x-2">
            <img src="https://i.pravatar.cc/32?img=52" alt="Dr. Jonathan Bertman" className="h-8 w-8 rounded-full" />
            {showUserDetails && (
              <span className="text-sm font-medium">J. Bertman</span>
            )}
          </div>
        ) : (
          content.icon
        )}
      </button>
      {openDropdown === name && (
        <div 
          className="absolute right-0 mt-2 w-64 rounded-md shadow-lg overflow-hidden z-50"
          style={{ backgroundColor: 'var(--color-background)', color: 'var(--color-text)' }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-4">
            {content.dropdownContent}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <header 
      ref={navRef} 
      className="top-navigation h-16 flex-shrink-0 shadow-sm px-4 flex justify-between items-center fixed top-0 left-0 right-0"
      style={{ 
        backgroundColor: 'var(--color-background)', 
        color: 'var(--color-text)',
        zIndex: 30
      }}
    >
      <div className="flex items-center space-x-4">
        <img src={theme === 'light' ? amazingChartsLightLogo : amazingChartsDarkLogo} alt="Amazing Charts" className="h-8" />
        {showSearch && (
          <form onSubmit={handleSearchSubmit} className="relative">
            <div className="flex items-center rounded-full transition-all duration-300 w-96"
                 style={{ backgroundColor: 'var(--color-searchBackground)', color: 'var(--color-searchText)' }}>
              <SearchIcon className="h-5 w-5 ml-3" style={{ color: 'var(--color-searchText)' }} />
              <input
                ref={searchInputRef}
                type="text"
                placeholder={isListening ? "Listening..." : "Search..."}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-transparent border-none focus:ring-0 px-3 py-2"
                style={{ color: 'var(--color-searchText)' }}
              />
              <button
                type="button"
                onClick={startListening}
                className="p-2 rounded-full"
                style={{ backgroundColor: isListening ? 'var(--color-primary)' : 'transparent', color: 'var(--color-searchText)' }}
              >
                <Mic className="h-5 w-5" />
              </button>
              {searchTerm && (
                <button type="submit" className="p-2" style={{ color: 'var(--color-primary)' }}>
                  <Send className="h-5 w-5" />
                </button>
              )}
            </div>
          </form>
        )}
      </div>

      <div className="flex items-center space-x-2">
        {showCalendarDropdown && renderDropdown('schedule', {
          icon: <Calendar className="h-5 w-5" />,
          dropdownContent: (
            <div className="max-h-64 overflow-y-auto">
              {appointments.map((appointment, index) => (
                <div key={index} className="mb-2 p-2 rounded" 
                     style={{ backgroundColor: 'var(--color-highlight)', color: 'var(--color-text)' }}>
                  <p className="font-semibold">{appointment.time} - {appointment.patient.lastName}, {appointment.patient.firstName}</p>
                  <p className="text-sm">{appointment.reason}</p>
                </div>
              ))}
            </div>
          )
        })}

        {showMessagesDropdown && renderDropdown('messages', {
          icon: <MessageSquareIcon className="h-5 w-5" />,
          dropdownContent: (
            <div className="max-h-64 overflow-y-auto">
              {messages.map((message, index) => (
                <div key={index} className="mb-2 p-2 rounded flex justify-between items-start" 
                     style={{ backgroundColor: 'var(--color-highlight)', color: 'var(--color-text)' }}>
                  <div>
                    <p className="font-semibold">{message.from}</p>
                    <p className="text-sm">{message.subject}</p>
                    <p className="text-xs">{new Date(message.date).toLocaleString()}</p>
                  </div>
                  {message.priority === 'high' && (
                    <span className="text-xs font-semibold px-2 py-1 rounded" style={{ backgroundColor: 'var(--color-urgent)', color: 'var(--color-urgentText)' }}>
                      Urgent
                    </span>
                  )}
                </div>
              ))}
            </div>
          )
        })}

        {showHelp && renderDropdown('help', {
          icon: <HelpCircle className="h-5 w-5" />,
          dropdownContent: (
            <div>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Search help topics..."
                  value={helpSearchTerm}
                  onChange={(e) => setHelpSearchTerm(e.target.value)}
                  className="w-full p-2 rounded border"
                  style={{ 
                    backgroundColor: 'var(--color-searchBackground)',
                    color: 'var(--color-searchText)',
                    borderColor: 'var(--color-widgetBorder)'
                  }}
                />
              </div>
              <div className="space-y-2">
                <div className="p-2 hover:bg-[var(--color-highlight)] rounded cursor-pointer">
                  <Book className="h-4 w-4 inline mr-2" />
                  Documentation
                </div>
                <div className="p-2 hover:bg-[var(--color-highlight)] rounded cursor-pointer">
                  <MessageSquare className="h-4 w-4 inline mr-2" />
                  Contact Support
                </div>
                <div className="p-2 hover:bg-[var(--color-highlight)] rounded cursor-pointer">
                  <Shield className="h-4 w-4 inline mr-2" />
                  Privacy Policy
                </div>
              </div>
            </div>
          )
        })}

        {showThemeButton && (
          <button
            onClick={toggleTheme}
            className="p-2 hover:bg-opacity-70 transition-colors duration-200 rounded-md"
            style={{ color: 'var(--color-text)' }}
          >
            {theme === 'light' ? <Moon className="h-5 w-5" /> : <Sun className="h-5 w-5" />}
          </button>
        )}

        {renderDropdown('userMenu', {
          dropdownContent: (
            <div>
              <div className="py-2 px-4 hover:bg-opacity-80 cursor-pointer flex items-center" onClick={() => {
                handleDropdownToggle('userMenu');
                setCurrentView('Settings');
              }}>
                <Settings className="h-5 w-5 mr-2" /> Settings
              </div>
              <div className="py-2 px-4 hover:bg-opacity-80 cursor-pointer flex items-center">
                <Lock className="h-5 w-5 mr-2" /> Lock Screen
              </div>
              <div className="py-2 px-4 hover:bg-opacity-80 cursor-pointer flex items-center">
                <LogOut className="h-5 w-5 mr-2" /> Log Out
              </div>
            </div>
          )
        })}

        <button 
          className="p-2 rounded-full"
          style={{ 
            backgroundColor: isAIOpen ? 'var(--color-aiIconBackground)' : 'var(--color-aiIconBackgroundUnpressed)', 
            color: 'var(--color-aiIconColor)'
          }}
          onClick={handleAIAssistantClick}
        >
          <Brain className="h-5 w-5" />
        </button>
      </div>
    </header>
  );
}