import React, { createContext, useContext, useReducer, useEffect } from 'react';

// Initial state
const initialState = {
  currentView: 'Dashboard',
  isSidebarOpen: true,
  isAIOpen: false,
  displayAJReminder: true,
  selectedPatient: null,
  topNavVisibility: {
    showSearch: true,
    showCalendarDropdown: true,
    showMessagesDropdown: true,
    showHelp: true,
    showThemeButton: true,
    showUserDetails: true
  },
  dashboardLayout: null, // Will be loaded from localStorage if available
  notifications: [],
  searchHistory: [],
  recentPatients: []
};

// Action types
const ActionTypes = {
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  TOGGLE_AI: 'TOGGLE_AI',
  TOGGLE_AJ_REMINDER: 'TOGGLE_AJ_REMINDER',
  SET_SELECTED_PATIENT: 'SET_SELECTED_PATIENT',
  UPDATE_TOP_NAV_VISIBILITY: 'UPDATE_TOP_NAV_VISIBILITY',
  SET_DASHBOARD_LAYOUT: 'SET_DASHBOARD_LAYOUT',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  ADD_SEARCH_HISTORY: 'ADD_SEARCH_HISTORY',
  ADD_RECENT_PATIENT: 'ADD_RECENT_PATIENT'
};

// Reducer
function globalUIReducer(state, action) {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    case ActionTypes.TOGGLE_SIDEBAR:
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    case ActionTypes.TOGGLE_AI:
      return { ...state, isAIOpen: !state.isAIOpen };
    case ActionTypes.TOGGLE_AJ_REMINDER:
      return { ...state, displayAJReminder: !state.displayAJReminder };
    case ActionTypes.SET_SELECTED_PATIENT:
      // Add to recent patients if not already there
      const updatedRecentPatients = [action.payload, ...state.recentPatients
        .filter(p => p.id !== action.payload.id)
        .slice(0, 4)];
      return { 
        ...state, 
        selectedPatient: action.payload,
        recentPatients: updatedRecentPatients
      };
    case ActionTypes.UPDATE_TOP_NAV_VISIBILITY:
      return {
        ...state,
        topNavVisibility: {
          ...state.topNavVisibility,
          [action.payload.key]: action.payload.value
        }
      };
    case ActionTypes.SET_DASHBOARD_LAYOUT:
      localStorage.setItem('dashboardLayout', JSON.stringify(action.payload));
      return { ...state, dashboardLayout: action.payload };
    case ActionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };
    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.id !== action.payload)
      };
    case ActionTypes.ADD_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: [action.payload, ...state.searchHistory.slice(0, 9)]
      };
    case ActionTypes.ADD_RECENT_PATIENT:
      return {
        ...state,
        recentPatients: [action.payload, ...state.recentPatients.slice(0, 4)]
      };
    default:
      return state;
  }
}

// Context
const GlobalUIContext = createContext();

// Provider
export function GlobalUIProvider({ children }) {
  const [state, dispatch] = useReducer(globalUIReducer, initialState);

  // Load saved layout on mount
  useEffect(() => {
    const savedLayout = localStorage.getItem('dashboardLayout');
    if (savedLayout) {
      dispatch({ 
        type: ActionTypes.SET_DASHBOARD_LAYOUT, 
        payload: JSON.parse(savedLayout) 
      });
    }
  }, []);

  // Helper functions
  const addNotification = (message, type = 'info') => {
    const id = Date.now();
    dispatch({
      type: ActionTypes.ADD_NOTIFICATION,
      payload: { id, message, type, timestamp: new Date() }
    });
    // Auto remove after 5 seconds
    setTimeout(() => {
      dispatch({ type: ActionTypes.REMOVE_NOTIFICATION, payload: id });
    }, 5000);
  };

  const selectPatient = (patient) => {
    dispatch({ type: ActionTypes.SET_SELECTED_PATIENT, payload: patient });
    addNotification(`Patient ${patient.firstName} ${patient.lastName} selected`);
  };

  const value = {
    state,
    dispatch,
    addNotification,
    selectPatient
  };

  return (
    <GlobalUIContext.Provider value={value}>
      {children}
    </GlobalUIContext.Provider>
  );
}

// Hook
export function useGlobalUI() {
  const context = useContext(GlobalUIContext);
  if (context === undefined) {
    throw new Error('useGlobalUI must be used within a GlobalUIProvider');
  }
  return context;
}

// Export action types for use in components
export { ActionTypes };
