import React, { useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Brain } from 'lucide-react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { AutoWidgetWrapper } from '../common';
import {
  TodaySchedule,
  Inbox,
  LabsToReview,
  Tasks,
  OpenCharts,
  DeniedClaims,
  MonthlyCalendar,
  QuickActions
} from '../widgets';

// Import mock data
import {
  appointments,
  messages,
  labsToReview,
  tasks,
  openCharts,
  deniedClaims,
  inboxMessages
} from '../../data/mockDatabase';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({ isAIOpen, toggleAI, displayAJReminder }) => {
  const [currentDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());
  const [gridWidth, setGridWidth] = useState(window.innerWidth);

  const [layouts, setLayouts] = useState({
    lg: [
      { i: 'todaySchedule', x: 0, y: 0, w: 2, h: 2 },
      { i: 'inbox', x: 2, y: 0, w: 2, h: 2 },
      { i: 'labsToReview', x: 4, y: 0, w: 2, h: 2 },
      { i: 'tasks', x: 0, y: 2, w: 2, h: 2 },
      { i: 'openCharts', x: 2, y: 2, w: 2, h: 2 },
      { i: 'deniedClaims', x: 4, y: 2, w: 2, h: 2 },
      { i: 'monthlyCalendar', x: 0, y: 4, w: 3, h: 2 },
      { i: 'quickActions', x: 3, y: 4, w: 3, h: 2 },
    ],
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setGridWidth(isAIOpen ? window.innerWidth - 320 : window.innerWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAIOpen]);

  const onLayoutChange = (currentLayout, allLayouts) => {
    setLayouts(allLayouts);
  };

  return (
    <div className="p-4 md:p-8" style={{ backgroundColor: 'var(--color-background)', color: 'var(--color-text)' }}>
      <header className="mb-6 md:mb-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex items-center mb-4 md:mb-0">
            <div className="h-12 w-12 rounded-full bg-[var(--color-primary)] flex items-center justify-center text-white text-xl font-bold mr-4">
              JB
            </div>
            <div>
              <h1 className="text-2xl font-bold">Welcome, Dr. Bertman</h1>
              <p style={{ color: 'var(--color-secondary)' }}>
                {currentDate.toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
              <p style={{ color: 'var(--color-secondary)' }}>
                {currentTime.toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}
              </p>
            </div>
          </div>
          {!isAIOpen && displayAJReminder && (
            <button 
              className="button flex items-center px-4 py-2 rounded-md ml-auto md:ml-0 mr-4 md:mr-8"
              style={{
                backgroundColor: 'var(--color-aiIconBackgroundUnpressed)',
                color: 'white'
              }}
              onClick={toggleAI}
            >
              <Brain className="mr-2 h-4 w-4" />
              Use AI to make it easier.
            </button>
          )}
        </div>
      </header>

      <div style={{ 
        width: '100%',
        transition: 'width 0.3s ease-in-out',
      }}>
        <ResponsiveGridLayout
          className="layout"
          layouts={layouts}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
          rowHeight={150}
          width={gridWidth}
          onLayoutChange={onLayoutChange}
          isResizable={true}
          isDraggable={true}
          draggableHandle=".cursor-move"
          margin={[16, 16]}
          containerPadding={[0, 0]}
        >
          <div key="todaySchedule">
            <AutoWidgetWrapper title="Today's Schedule">
              <TodaySchedule appointments={appointments} />
            </AutoWidgetWrapper>
          </div>

          <div key="inbox">
            <AutoWidgetWrapper title="Inbox">
              <Inbox messages={messages} inboxMessages={inboxMessages} />
            </AutoWidgetWrapper>
          </div>

          <div key="labsToReview">
            <AutoWidgetWrapper title="Labs to Review">
              <LabsToReview labs={labsToReview} />
            </AutoWidgetWrapper>
          </div>

          <div key="tasks">
            <AutoWidgetWrapper title="Tasks">
              <Tasks tasks={tasks} />
            </AutoWidgetWrapper>
          </div>

          <div key="openCharts">
            <AutoWidgetWrapper title="Open Charts">
              <OpenCharts charts={openCharts} />
            </AutoWidgetWrapper>
          </div>

          <div key="deniedClaims">
            <AutoWidgetWrapper title="Denied Claims">
              <DeniedClaims claims={deniedClaims} />
            </AutoWidgetWrapper>
          </div>

          <div key="monthlyCalendar">
            <AutoWidgetWrapper title="Monthly Calendar">
              <MonthlyCalendar />
            </AutoWidgetWrapper>
          </div>

          <div key="quickActions">
            <AutoWidgetWrapper title="Quick Actions">
              <QuickActions />
            </AutoWidgetWrapper>
          </div>
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default Dashboard;