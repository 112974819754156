import React, { useState, useEffect } from 'react';
import { getPracticeData, getDoctorData, getRevenueBreakdownData, getOutstandingClaimsData } from '../../data/dataServices';
import MetricsOverview from '../widgets/MetricsOverview'; // Changed to default import
import PatientList from '../widgets/PatientList';
import ClaimsSection from '../widgets/ClaimsSection';
import RevenueBreakdown from '../widgets/RevenueBreakdown';

// Helper function to format numbers with commas and handle undefined values
const formatNumber = (num) => {
  return num && !isNaN(num)
    ? num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "N/A";
}

const AutoWidgetWrapper = ({ children, title }) => (
   <div 
     className="bg-[var(--color-widgetBackground)] p-4 rounded-lg shadow mb-6 transition-all duration-300 hover:shadow-lg"
     style={{ 
       border: '1px solid var(--color-widgetBorder)',
       animation: 'fadeIn 0.3s ease-in'
     }}
   >
     <h2 className="text-lg font-semibold mb-4 flex justify-between items-center" style={{ color: 'var(--color-widgetHeaderText)' }}>
       {title}
       <span className="text-xs text-[var(--color-secondary)]">Auto-updating</span>
     </h2>
     {children}
   </div>
);
export default function PracticeManagement() {
  const [showRevenueBreakdown, setShowRevenueBreakdown] = useState(false);
  const [showClaimsBreakdown, setShowClaimsBreakdown] = useState(false);
  const [practiceData, setPracticeData] = useState(null);
  const [doctorData, setDoctorData] = useState([]);
  const [revenueBreakdownData, setRevenueBreakdownData] = useState([]);
  const [outstandingClaimsData, setOutstandingClaimsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [practice, doctors, revenue, claims] = await Promise.all([
          getPracticeData(),
          getDoctorData(),
          getRevenueBreakdownData(),
          getOutstandingClaimsData()
        ]);
        console.log('Fetched Data:', { practice, doctors, revenue, claims }); // Debugging log
        setPracticeData(practice);
        setDoctorData(doctors);
        setRevenueBreakdownData(revenue);
        setOutstandingClaimsData(claims);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message || 'An error occurred while fetching data');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) return <div className="p-8 bg-[var(--color-background)] text-[var(--color-text)]">Loading...</div>;
  
  if (error) {
    return (
      <div className="p-8 bg-[var(--color-background)] text-[var(--color-text)]">
        <h2 className="text-2xl font-bold mb-4">Error</h2>
        <p className="text-red-500">{error}</p>
        <p className="mt-4">Please check the console for more detailed error information.</p>
      </div>
    );
  }

  if (!practiceData) return null;

  return (
    <div className="p-8 bg-[var(--color-background)] text-[var(--color-text)] min-h-screen">
      <h1 className="text-2xl font-bold mb-6">Practice Management</h1>
      
      {/* Existing Metrics Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <AutoWidgetWrapper title="Total Revenue (YTD)">
          <div
            className="cursor-pointer hover:bg-[var(--color-highlight)] transition-colors duration-200"
            onClick={() => setShowRevenueBreakdown(!showRevenueBreakdown)}
          >
            <p className="text-2xl font-bold">${formatNumber(practiceData.totalRevenue)}</p>
            <p className="text-sm text-[var(--color-secondary)]">+{formatNumber(practiceData.growthRate)}% from last year</p>
            <p className="text-xs text-[var(--color-primary)] mt-2">Click to {showRevenueBreakdown ? 'hide' : 'show'} breakdown</p>
          </div>
        </AutoWidgetWrapper>
        
        <AutoWidgetWrapper title="Outstanding Claims">
          <div
            className="cursor-pointer hover:bg-[var(--color-highlight)] transition-colors duration-200"
            onClick={() => setShowClaimsBreakdown(!showClaimsBreakdown)}
          >
            <p className="text-2xl font-bold">${formatNumber(practiceData.outstandingClaims)}</p>
            <p className="text-sm text-[var(--color-secondary)]">{practiceData.averageClaimProcessingTime} Avg. days in A/R</p>
            <p className="text-xs text-[var(--color-primary)] mt-2">Click to {showClaimsBreakdown ? 'hide' : 'show'} breakdown</p>
          </div>
        </AutoWidgetWrapper>
        
        <AutoWidgetWrapper title="Active Patients">
          <p className="text-2xl font-bold">{practiceData.activePatients.toLocaleString()}</p>
          <p className="text-sm text-[var(--color-secondary)]">{practiceData.totalPatients.toLocaleString()} total patients</p>
        </AutoWidgetWrapper>
        
        <AutoWidgetWrapper title="Scheduled Visits">
          <p className="text-2xl font-bold">{practiceData.appointmentsThisMonth.toLocaleString()}</p>
        </AutoWidgetWrapper>
      </div>

      {/* Revenue Breakdown Section */}
      {showRevenueBreakdown && (
        <AutoWidgetWrapper title="Revenue Breakdown by Payor">
          <table className="w-full">
            <thead>
              <tr className="bg-[var(--color-highlight)]">
                <th className="text-left p-2">Payor</th>
                <th className="text-left p-2">Revenue Received</th>
                <th className="text-left p-2">Claims</th>
                <th className="text-left p-2">Patients</th>
                <th className="text-left p-2">$/Patient</th>
                <th className="text-left p-2">Claim Denial Rate</th>
              </tr>
            </thead>
            <tbody>
              {revenueBreakdownData.map((payor, index) => (
                <tr key={index} className="border-b border-[var(--color-secondary)]">
                  <td className="p-2">{payor.payor}</td>
                  <td className="p-2">${formatNumber(payor.revenueReceived)}</td>
                  <td className="p-2">{payor.claims.toLocaleString()}</td>
                  <td className="p-2">{payor.patients.toLocaleString()}</td>
                  <td className="p-2">${formatNumber(payor.dollarPerPatient)}</td>
                  <td className="p-2">{formatNumber(payor.claimDenialRate)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </AutoWidgetWrapper>
      )}

      {/* Claims Breakdown Section */}
      {showClaimsBreakdown && (
        <AutoWidgetWrapper title="Outstanding Claims Breakdown by Payor">
          <table className="w-full">
            <thead>
              <tr className="bg-[var(--color-highlight)]">
                <th className="text-left p-2">Payor</th>
                <th className="text-left p-2">Outstanding Amount</th>
                <th className="text-left p-2">Number of Claims</th>
                <th className="text-left p-2">Avg. Days Outstanding</th>
              </tr>
            </thead>
            <tbody>
              {outstandingClaimsData.map((payor, index) => (
                <tr key={index} className="border-b border-[var(--color-secondary)]">
                  <td className="p-2">{payor.payor}</td>
                  <td className="p-2">${formatNumber(payor.amount)}</td>
                  <td className="p-2">{payor.claims.toLocaleString()}</td>
                  <td className="p-2">{payor.avgDaysOutstanding}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </AutoWidgetWrapper>
      )}

      {/* Doctor Performance Section */}
      <AutoWidgetWrapper title="Doctor Performance">
        <table className="w-full">
          <thead>
            <tr className="bg-[var(--color-highlight)]">
              <th className="text-left p-2">Doctor</th>
              <th className="text-left p-2">Patients Seen (Month)</th>
              <th className="text-left p-2">Revenue Generated (YTD)</th>
              <th className="text-left p-2">Claim Denial Rate</th>
            </tr>
          </thead>
          <tbody>
            {doctorData.map((doctor, index) => (
              <tr key={index} className="border-b border-[var(--color-secondary)]">
                <td className="p-2">{doctor.name}</td>
                <td className="p-2">{doctor.patientsSeenThisMonth.toLocaleString()}</td>
                <td className="p-2">${formatNumber(doctor.revenueGenerated)}</td>
                <td className="p-2">{doctor.claimsDenialRate.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </AutoWidgetWrapper>

      {/* New Widgets Section */}
      <div className="mt-8">
        <AutoWidgetWrapper title="Practice Overview">
          <MetricsOverview />
        </AutoWidgetWrapper>

        <AutoWidgetWrapper title="Patients">
          <PatientList />
        </AutoWidgetWrapper>

        <AutoWidgetWrapper title="Outstanding Claims">
          <ClaimsSection />
        </AutoWidgetWrapper>

        <AutoWidgetWrapper title="Revenue Breakdown">
          <RevenueBreakdown />
        </AutoWidgetWrapper>
      </div>
    </div>
  );
}
