import React, { useState, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import { ChevronUp, ChevronDown, FileText, UserPlus, PhoneCall, Monitor, MoreHorizontal } from 'lucide-react';
import { useGlobalUI, ActionTypes } from '../../context/GlobalUIContext';

const PatientsView = ({ patients }) => {
  const [sortConfig, setSortConfig] = useState({ key: 'lastName', direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  const { dispatch } = useGlobalUI();

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' 
        ? <ChevronUp className="inline h-4 w-4 ml-1" />
        : <ChevronDown className="inline h-4 w-4 ml-1" />;
    }
    return null;
  };

  const sortedPatients = useMemo(() => {
    let filteredPatients = patients ? [...patients] : [];

    if (searchTerm) {
      filteredPatients = filteredPatients.filter(patient => 
        patient.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.phoneNumber?.includes(searchTerm)
      );
    }

    return filteredPatients.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Special handling for different field types
      if (sortConfig.key === 'age') {
        aValue = calculateAge(a.dateOfBirth);
        bValue = calculateAge(b.dateOfBirth);
      } else if (sortConfig.key === 'dateOfBirth') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [patients, sortConfig, searchTerm]);

  // Row renderer for react-window
  const Row = ({ index, style }) => {
    const patient = sortedPatients[index];
    return (
      <div 
        style={{ ...style, height: '60px' }} 
        className="grid grid-cols-10 gap-2 p-1 border-b hover:bg-[var(--color-highlight)] text-base text-left items-center"
      >
        <div>{patient.id}</div>
        <div className="flex items-center col-span-2 whitespace-nowrap overflow-hidden text-ellipsis">
          <img 
            src={patient.image} 
            alt={`${patient.firstName} ${patient.lastName}`} 
            className="w-8 h-8 rounded-full mr-2"
          />
          {patient.lastName}, {patient.firstName}
        </div>
        <div>{new Date(patient.dateOfBirth).toLocaleDateString()}</div>
        <div>{calculateAge(patient.dateOfBirth)}</div>
        <div>{patient.gender}</div>
        <div>{patient.phoneNumber}</div>
        <div>{patient.lastVisit}</div>
        <div>${patient.accountStatus?.balance?.toFixed(2) || '0.00'}</div>
        <div className="flex space-x-1 items-center">
          <button
            className="w-8 h-8 p-1 rounded"
            style={{ 
              backgroundColor: 'var(--color-primary)', 
              color: 'var(--color-buttonText)'
            }}
            onClick={() => {
              dispatch({ 
                type: ActionTypes.ADD_NOTIFICATION, 
                payload: { message: 'Opening patient chart...', type: 'info' }
              });
            }}
          >
            <FileText className="h-4 w-4" />
          </button>
          <button
            className="w-8 h-8 p-1 rounded"
            style={{ 
              backgroundColor: 'var(--color-accent)', 
              color: 'var(--color-buttonText)'
            }}
            onClick={() => {
              dispatch({ 
                type: ActionTypes.ADD_NOTIFICATION, 
                payload: { message: 'Scheduling appointment...', type: 'info' }
              });
            }}
          >
            <UserPlus className="h-4 w-4" />
          </button>
          <button
            className="w-8 h-8 p-1 rounded"
            style={{ 
              backgroundColor: 'var(--color-secondary)', 
              color: 'var(--color-buttonText)'
            }}
          >
            <PhoneCall className="h-4 w-4" />
          </button>
          <button
            className="w-8 h-8 p-1 rounded"
            style={{ 
              backgroundColor: 'var(--color-tertiary)', 
              color: 'var(--color-buttonText)'
            }}
          >
            <Monitor className="h-4 w-4" />
          </button>
          <button
            className="w-8 h-8 p-1 rounded"
            style={{ 
              backgroundColor: 'var(--color-quaternary)', 
              color: 'var(--color-buttonText)'
            }}
          >
            <MoreHorizontal className="h-4 w-4" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="p-2">
      <input
        type="text"
        placeholder="Search by name, email, or phone..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="p-2 mb-4 w-full rounded border text-base"
        style={{ 
          backgroundColor: 'var(--color-searchBackground)',
          color: 'var(--color-searchText)',
          borderColor: 'var(--color-widgetBorder)'
        }}
      />

      <div className="widget-data-grid">
        <div className="grid grid-cols-10 gap-2 p-2 font-medium cursor-pointer text-base text-left"
             style={{ backgroundColor: 'var(--color-primary)', color: 'white' }}>
          <div onClick={() => handleSort("id")}>
            ID {getSortIndicator("id")}
          </div>
          <div className="col-span-2" onClick={() => handleSort("lastName")}>
            Name {getSortIndicator("lastName")}
          </div>
          <div onClick={() => handleSort("dateOfBirth")}>
            DOB {getSortIndicator("dateOfBirth")}
          </div>
          <div onClick={() => handleSort("age")}>
            Age {getSortIndicator("age")}
          </div>
          <div onClick={() => handleSort("gender")}>
            Gender {getSortIndicator("gender")}
          </div>
          <div onClick={() => handleSort("phoneNumber")}>
            Phone {getSortIndicator("phoneNumber")}
          </div>
          <div onClick={() => handleSort("lastVisit")}>
            Last Visit {getSortIndicator("lastVisit")}
          </div>
          <div onClick={() => handleSort("balance")}>
            Balance {getSortIndicator("balance")}
          </div>
          <div>Actions</div>
        </div>

        <List
          height={500} // Set the height of the virtualized list
          itemCount={sortedPatients.length}
          itemSize={60} // Increase item height to give more space to each row
          width="100%"
        >
          {Row}
        </List>
      </div>
    </div>
  );
};

export default PatientsView;