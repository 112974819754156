// src/context/AIContext.jsx
import React, { createContext, useContext, useReducer } from 'react';

const AIContext = createContext();

const initialState = {
  isOpen: false,
  currentWidget: null,
  chatHistory: [],
  activeSession: null
};

function aiReducer(state, action) {
  switch (action.type) {
    case 'OPEN_AI':
      return {
        ...state,
        isOpen: true,
        currentWidget: action.payload.widgetData,
        activeSession: {
          id: Date.now(),
          startTime: new Date().toISOString(),
          widgetId: action.payload.widgetData.widgetTitle
        }
      };
    case 'CLOSE_AI':
      return {
        ...state,
        isOpen: false,
        currentWidget: null,
        activeSession: null
      };
    case 'ADD_CHAT_MESSAGE':
      return {
        ...state,
        chatHistory: [...state.chatHistory, {
          ...action.payload,
          sessionId: state.activeSession?.id
        }]
      };
    case 'CLEAR_CHAT':
      return {
        ...state,
        chatHistory: []
      };
    default:
      return state;
  }
}

export function AIProvider({ children }) {
  const [state, dispatch] = useReducer(aiReducer, initialState);

  const openAI = (widgetData) => {
    dispatch({ 
      type: 'OPEN_AI', 
      payload: { widgetData }
    });
  };

  const closeAI = () => {
    dispatch({ type: 'CLOSE_AI' });
  };

  const addMessage = (message) => {
    dispatch({
      type: 'ADD_CHAT_MESSAGE',
      payload: {
        ...message,
        timestamp: new Date().toISOString()
      }
    });
  };

  const value = {
    state,
    openAI,
    closeAI,
    addMessage
  };

  return (
    <AIContext.Provider value={value}>
      {children}
    </AIContext.Provider>
  );
}

export function useAI() {
  const context = useContext(AIContext);
  if (!context) {
    throw new Error('useAI must be used within an AIProvider');
  }
  return context;
}