import React, { useState, useEffect } from 'react';
import { X, Mic, FileText, FileOutput, UserPlus } from 'lucide-react';

export default function AIAssistant({ isOpen, toggleChat }) {
  const [input, setInput] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [chatMessages, setChatMessages] = useState([
    { text: "Hello! I'm your AI assistant. How can I help you today?", sender: 'ai' }
  ]);

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);

  useEffect(() => {
    let timer;
    if (isListening) {
      timer = setTimeout(() => {
        setShowNotice(true);
      }, 3000);
    } else {
      setShowNotice(false);
    }
    return () => clearTimeout(timer);
  }, [isListening]);

  const resetState = () => {
    setInput('');
    setIsListening(false);
    setShowNotice(false);
    setChatMessages([
      { text: "Hello! I'm AJ, your AI assistant. How can I help you today?", sender: 'ai' }
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      setChatMessages([...chatMessages, { text: input, sender: 'user' }]);
      setTimeout(() => {
        setChatMessages(prev => [...prev, { text: "Analyzing your request...", sender: 'ai' }]);
      }, 1000);
      setInput('');
    }
  };

  return (
    <div 
      className={`ai-assistant fixed transform transition-transform duration-300 shadow-lg ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      style={{ 
        backgroundColor: 'var(--color-background)', 
        color: 'var(--color-text)',
        top: '64px', // Height of TopNavigation
        right: 0,
        bottom: 0,
        width: '320px',
        zIndex: 40
      }}
    >
      <div 
        className="flex justify-between items-center p-4 border-b rounded-t-lg" 
        style={{ 
          borderColor: 'var(--color-secondary)', 
          backgroundColor: 'var(--color-aiIconBackgroundUnpressed)'
        }}
      >
        <h2 className="text-lg font-semibold text-white">AI Integration</h2>
        <button onClick={toggleChat} className="text-white hover:text-gray-200">
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="flex justify-around p-4 border-b" style={{ borderColor: 'var(--color-secondary)' }}>
        {['Analyze Content', 'Generate Output', 'Clinical Discussion'].map((action, index) => (
          <button 
            key={index}
            onClick={() => console.log(`Clicked ${action}`)}
            className="flex flex-col items-center p-2 rounded hover:opacity-80 transition-colors duration-200"
            style={{ 
              backgroundColor: 'var(--color-aiButtonBackground)', 
              color: 'var(--color-aiButtonText)', 
              width: '30%' 
            }}
          >
            {index === 0 && <FileText className="h-6 w-6 mb-1" />}
            {index === 1 && <FileOutput className="h-6 w-6 mb-1" />}
            {index === 2 && <UserPlus className="h-6 w-6 mb-1" />}
            <span className="text-xs text-center">{action}</span>
          </button>
        ))}
      </div>

      <div className="flex-1 p-4 overflow-y-auto">
        {chatMessages.map((msg, index) => (
          <div 
            key={index} 
            className={`mb-4 ${msg.sender === 'user' ? 'text-right' : 'text-left'}`}
          >
            <span 
              className={`inline-block p-3 rounded-lg shadow-md ${
                msg.sender === 'user' 
                  ? 'bg-[var(--color-aiButtonBackground)] text-[var(--color-aiButtonText)]' 
                  : 'bg-[var(--color-primary)] text-white'
              }`}
            >
              {msg.text}
            </span>
          </div>
        ))}
      </div>
      <form 
        onSubmit={handleSubmit} 
        className="p-4 border-t flex relative"
        style={{ borderColor: 'var(--color-secondary)' }}
      >
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type or click mic to speak..."
          className="flex-1 p-2 border rounded-l focus:outline-none focus:ring-2 focus:ring-[var(--color-primary)]"
          style={{ 
            backgroundColor: 'var(--color-background)', 
            color: 'var(--color-text)', 
            borderColor: 'var(--color-secondary)' 
          }}
        />
        <button 
          type="button" 
          onClick={() => setIsListening(!isListening)} 
          className="p-2 rounded-r transition-colors duration-300"
          style={{ 
            backgroundColor: isListening ? 'var(--color-accent)' : 'var(--color-aiButtonBackground)', 
            color: 'var(--color-aiButtonText)'
          }}
        >
          <Mic className="h-5 w-5" />
        </button>

        {isListening && (
          <div 
            className="absolute bottom-full left-0 right-0 p-4 shadow-lg rounded-t-lg border border-gray-200 flex flex-col items-center justify-center"
            style={{ 
              backgroundColor: 'var(--color-background)', 
              borderColor: 'var(--color-secondary)' 
            }}
          >
            <div className="flex items-center">
              <div className="animate-pulse mr-2 h-3 w-3 bg-red-500 rounded-full"></div>
              <span className="text-lg font-semibold">I'm listening...</span>
            </div>
            {showNotice && (
              <span 
                className="text-sm mt-2" 
                style={{ color: 'var(--color-secondary)' }}
              >
                (Not really. Not yet anyway...)
              </span>
            )}
          </div>
        )}
      </form>
    </div>
  );
}