const themes = {
  light: {
    name: 'Light',
    background: '#e2e8f0',
    text: '#000000',
    primary: '#6B82BC',
    secondary: '#4A5568',
    accent: '#FF8C42',
    highlight: '#cbd5e1',
    sidebarBackground: '#6B82BC',
    sidebarText: '#ffffff',
    searchBackground: '#f1f5f9',
    searchText: '#333333',
    mutedText: '#6C757D',
    aiButtonBackground: '#6B82BC',
    aiButtonText: '#ffffff',
    aiIconBackgroundUnpressed: '#FFA500',
    aiIconBackground: '#6B82BC',
    aiIconColor: '#ffffff',
    buttonBackground: '#6B82BC',
    buttonText: '#ffffff',
    buttonHoverBackground: '#5a6fa8',
    widgetBackground: '#f0f0f0',
    widgetBorder: '#94a3b8',
    widgetHeaderBackground: '#d1d5db',
    widgetHeaderText: '#000000',
  },  dark: {
    name: 'Dark',
    background: '#121620',
    text: '#ffffff',
    primary: '#7B92CC',
    secondary: '#E2E8F0',
    accent: '#FFA030',
    highlight: '#2D3748',
    sidebarBackground: '#1E2A3E',
    sidebarText: '#ffffff',
    searchBackground: '#2D3748',
    searchText: '#ffffff',
    mutedText: '#B0BEC5',
    aiButtonBackground: '#7B92CC',
    aiButtonText: '#ffffff',
    aiIconBackgroundUnpressed: '#FFA030',
    aiIconBackground: '#7B92CC',
    aiIconColor: '#ffffff',
    buttonBackground: '#7B92CC',
    buttonText: '#ffffff',
    buttonHoverBackground: '#6A81BB',
    widgetBackground: '#1A2433',
    widgetBorder: '#4A5A73',
    widgetHeaderBackground: '#34495E',
    widgetHeaderText: '#ffffff',
  },
};

export default themes;
