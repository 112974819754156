import React from 'react';

const Inbox = ({ messages, inboxMessages }) => {
  return (
    <div className="h-full p-4">
      <div className="space-y-4">
        {inboxMessages?.map((message, index) => (
          <div key={index} className="flex items-center justify-between p-2 border-b last:border-0"
               style={{ borderColor: 'var(--color-widgetBorder)' }}>
            <div>
              <p className="font-semibold">{message.sender}</p>
              <p className="text-sm text-[var(--color-secondary)]">{message.subject}</p>
            </div>
            {message.urgent && (
              <span className="px-2 py-1 text-xs rounded-full"
                    style={{ 
                      backgroundColor: 'var(--color-urgent)',
                      color: 'var(--color-urgentText)'
                    }}>
                Urgent
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Inbox;