import React, { useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { Settings as SettingsIcon, Save, RotateCcw, Moon, Sun, Bell, Shield, Users, Database } from 'lucide-react';

export default function Settings() {
  const { theme, toggleTheme } = useTheme();
  const [activeTab, setActiveTab] = useState('general');

  const settingsCategories = [
    {
      id: 'general',
      name: 'General Settings',
      icon: SettingsIcon,
      settings: [
        { id: 'theme', name: 'Theme Mode', value: theme, type: 'toggle' },
        { id: 'notifications', name: 'Notifications', value: 'enabled', type: 'toggle' },
        { id: 'language', name: 'Language', value: 'English', type: 'select' }
      ]
    },
    {
      id: 'security',
      name: 'Security',
      icon: Shield,
      settings: [
        { id: '2fa', name: 'Two-Factor Authentication', value: 'disabled', type: 'toggle' },
        { id: 'session', name: 'Session Timeout', value: '30 minutes', type: 'select' }
      ]
    },
    {
      id: 'preferences',
      name: 'User Preferences',
      icon: Users,
      settings: [
        { id: 'defaultView', name: 'Default View', value: 'Dashboard', type: 'select' },
        { id: 'timezone', name: 'Timezone', value: 'UTC-5', type: 'select' }
      ]
    }
  ];

  return (
    <div className="flex-1 p-6 min-h-screen overflow-auto" style={{ backgroundColor: 'var(--color-background)' }}>
      <h1 className="text-2xl font-bold mb-6" style={{ color: 'var(--color-text)' }}>Settings</h1>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-3">
          <div className="bg-white rounded-lg shadow" style={{ backgroundColor: 'var(--color-widgetBackground)' }}>
            {settingsCategories.map(category => (
              <button
                key={category.id}
                onClick={() => setActiveTab(category.id)}
                className={`w-full p-4 flex items-center space-x-3 ${activeTab === category.id ? 'bg-opacity-80' : ''}`}
                style={{ 
                  backgroundColor: activeTab === category.id ? 'var(--color-primary)' : 'transparent',
                  color: activeTab === category.id ? 'white' : 'var(--color-text)'
                }}
              >
                <category.icon className="h-5 w-5" />
                <span>{category.name}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="col-span-9">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="p-6" style={{ backgroundColor: 'var(--color-widgetBackground)' }}>
              {settingsCategories.find(c => c.id === activeTab)?.settings.map(setting => (
                <div key={setting.id} className="mb-6 last:mb-0">
                  <div className="flex justify-between items-center p-4 rounded-lg" 
                       style={{ backgroundColor: 'var(--color-highlight)' }}>
                    <div>
                      <h3 className="font-medium" style={{ color: 'var(--color-text)' }}>{setting.name}</h3>
                      <p className="text-sm" style={{ color: 'var(--color-mutedText)' }}>
                        Current value: {setting.value}
                      </p>
                    </div>
                    {setting.type === 'toggle' ? (
                      <button
                        className="p-2 rounded-full"
                        style={{ 
                          backgroundColor: setting.value === 'enabled' || setting.value === 'light' 
                            ? 'var(--color-primary)' 
                            : 'var(--color-secondary)',
                          color: 'white'
                        }}
                        onClick={() => setting.id === 'theme' && toggleTheme()}
                      >
                        {setting.id === 'theme' ? (
                          theme === 'light' ? <Moon className="h-5 w-5" /> : <Sun className="h-5 w-5" />
                        ) : (
                          <Bell className="h-5 w-5" />
                        )}
                      </button>
                    ) : (
                      <select
                        className="p-2 rounded-lg"
                        style={{ 
                          backgroundColor: 'var(--color-searchBackground)',
                          color: 'var(--color-text)',
                          border: '1px solid var(--color-widgetBorder)'
                        }}
                      >
                        <option value={setting.value}>{setting.value}</option>
                        {/* Add more options as needed */}
                      </select>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}