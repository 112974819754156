import React from 'react';

const LabsToReview = ({ labs }) => (
  <div className="h-full p-4">
    <div className="space-y-4">
      {labs?.map((lab, index) => (
        <div key={index} className="border-b last:border-b-0 pb-3 last:pb-0"
             style={{ borderColor: 'var(--color-widgetBorder)' }}>
          <p className="font-semibold">{lab.patient}</p>
          <p>{lab.test}</p>
          <p className="text-sm text-[var(--color-secondary)]">Date: {lab.date}</p>
        </div>
      ))}
    </div>
  </div>
);

export default LabsToReview;