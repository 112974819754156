import React, { useState, useEffect } from 'react';
import { Search, Star, Trash, AlertCircle, X, Reply, Archive, Flag } from 'lucide-react';
import { messages as mockMessages } from '../../data/mockDatabase';

export default function Messages() {
  const [messages, setMessages] = useState(mockMessages);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all'); // all, unread, internal, external

  // Filter messages based on search term and filter type
  const filteredMessages = messages.filter((message) => {
    const matchesSearch = (
      message.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

    switch (filter) {
      case 'unread':
        return !message.read && matchesSearch;
      case 'internal':
        return message.type === 'internal' && matchesSearch;
      case 'external':
        return message.type === 'external' && matchesSearch;
      default:
        return matchesSearch;
    }
  });

  const handleMessageSelect = (message) => {
    setSelectedMessage(message);
    // Mark message as read
    setMessages(messages.map(msg => 
      msg.id === message.id ? { ...msg, read: true } : msg
    ));
  };

  const handleArchive = () => {
    if (selectedMessage) {
      setMessages(messages.filter(msg => msg.id !== selectedMessage.id));
      setSelectedMessage(null);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  };

  return (
    <div className="h-screen flex flex-col bg-[var(--color-background)]">
      {/* Header */}
      <div className="p-4 border-b" style={{ borderColor: 'var(--color-widgetBorder)' }}>
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Inbox</h1>
          <div className="flex space-x-2">
            <button 
              className={`px-3 py-1 rounded-md ${filter === 'all' ? 'bg-[var(--color-primary)] text-white' : 'bg-[var(--color-highlight)]'}`}
              onClick={() => setFilter('all')}
            >
              All
            </button>
            <button 
              className={`px-3 py-1 rounded-md ${filter === 'unread' ? 'bg-[var(--color-primary)] text-white' : 'bg-[var(--color-highlight)]'}`}
              onClick={() => setFilter('unread')}
            >
              Unread
            </button>
            <button 
              className={`px-3 py-1 rounded-md ${filter === 'internal' ? 'bg-[var(--color-primary)] text-white' : 'bg-[var(--color-highlight)]'}`}
              onClick={() => setFilter('internal')}
            >
              Internal
            </button>
            <button 
              className={`px-3 py-1 rounded-md ${filter === 'external' ? 'bg-[var(--color-primary)] text-white' : 'bg-[var(--color-highlight)]'}`}
              onClick={() => setFilter('external')}
            >
              External
            </button>
          </div>
        </div>
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search messages..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-lg border"
            style={{ 
              backgroundColor: 'var(--color-searchBackground)',
              color: 'var(--color-searchText)',
              borderColor: 'var(--color-widgetBorder)'
            }}
          />
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex overflow-hidden">
        {/* Message List */}
        <div className="w-1/3 border-r overflow-y-auto" style={{ borderColor: 'var(--color-widgetBorder)' }}>
          {filteredMessages.map((message) => (
            <div
              key={message.id}
              onClick={() => handleMessageSelect(message)}
              className={`p-4 border-b cursor-pointer hover:bg-[var(--color-highlight)] transition-colors ${
                selectedMessage?.id === message.id ? 'bg-[var(--color-highlight)]' : ''
              } ${!message.read ? 'font-semibold' : ''}`}
              style={{ borderColor: 'var(--color-widgetBorder)' }}
            >
              <div className="flex justify-between items-start mb-2">
                <span className="text-sm">{message.from}</span>
                <span className="text-xs text-[var(--color-mutedText)]">
                  {formatDate(message.date)}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm truncate">{message.subject}</span>
                {message.priority === 'high' && (
                  <AlertCircle className="h-4 w-4 text-[var(--color-urgent)]" />
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Message View */}
        {selectedMessage ? (
          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Message Header */}
            <div className="p-4 border-b" style={{ borderColor: 'var(--color-widgetBorder)' }}>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">{selectedMessage.subject}</h2>
                <div className="flex space-x-2">
                  <button className="p-2 hover:bg-[var(--color-highlight)] rounded-full">
                    <Reply className="h-5 w-5" />
                  </button>
                  <button className="p-2 hover:bg-[var(--color-highlight)] rounded-full">
                    <Archive className="h-5 w-5" onClick={handleArchive} />
                  </button>
                  <button className="p-2 hover:bg-[var(--color-highlight)] rounded-full">
                    <Flag className="h-5 w-5" />
                  </button>
                  <button className="p-2 hover:bg-[var(--color-highlight)] rounded-full">
                    <Trash className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-sm">
                    From: <span className="font-semibold">{selectedMessage.from}</span>
                  </p>
                  <p className="text-sm">
                    To: <span className="font-semibold">{selectedMessage.to}</span>
                  </p>
                </div>
                <span className="text-sm text-[var(--color-mutedText)]">
                  {formatDate(selectedMessage.date)}
                </span>
              </div>
            </div>
            {/* Message Content */}
            <div className="flex-1 p-6 overflow-y-auto">
              <p className="whitespace-pre-wrap">{selectedMessage.content}</p>
            </div>
          </div>
        ) : (
          <div className="flex-1 flex items-center justify-center text-[var(--color-mutedText)]">
            Select a message to view
          </div>
        )}
      </div>
    </div>
  );
}