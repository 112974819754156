import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  Sidebar,
  TopNavigation,
  AIAssistant,
  Dashboard,
  Patients,
  Schedule,
  Messages,
  PracticeManagement,
  Settings
} from './components';
import { AppProvider } from './providers/AppProvider';
import { appointments, messages as mockMessages, patients } from './data/mockDatabase';

function App() {
  const [isAIOpen, setIsAIOpen] = useState(false);
  const [displayAJReminder, setDisplayAJReminder] = useState(true);
  
  const toggleChat = () => {
    setIsAIOpen(prev => !prev);
  };

  return (
    <AppProvider>
      <Router>
        <div className="app-layout">
          <TopNavigation
            toggleChat={toggleChat}
            isAIOpen={isAIOpen}
            displayAJReminder={displayAJReminder}
          />
          
          <div className="app-main">
            <Sidebar />
            
            <main className={`content ${isAIOpen ? 'ai-open' : ''}`}>
              <Routes>
                <Route 
                  path="/" 
                  element={
                    <Dashboard
                      appointments={appointments}
                      isAIOpen={isAIOpen}
                      toggleAI={toggleChat}
                      displayAJReminder={displayAJReminder}
                    />
                  } 
                />
                <Route path="/patients" element={<Patients patients={patients} />} />
                <Route path="/schedule" element={<Schedule appointments={appointments} />} />
                <Route path="/messages" element={<Messages messages={mockMessages} />} />
                <Route path="/practice-management" element={<PracticeManagement />} />
                <Route path="/settings" element={<Settings />} />
              </Routes>
            </main>

            {isAIOpen && (
              <AIAssistant 
                isOpen={isAIOpen} 
                toggleChat={toggleChat} 
              />
            )}
          </div>
        </div>
      </Router>
    </AppProvider>
  );
}

export default App;