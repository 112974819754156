import React from 'react';

const TodaySchedule = ({ appointments }) => {
  return (
    <div className="h-full p-4">
      <div className="space-y-4">
        {appointments?.map((appointment, index) => (
          <div key={index} className="border-b last:border-b-0 pb-3 last:pb-0"
               style={{ borderColor: 'var(--color-widgetBorder)' }}>
            <p className="font-semibold">{appointment.time}</p>
            <p>{appointment.patient.firstName} {appointment.patient.lastName}</p>
            <p className="text-sm text-[var(--color-secondary)]">
              {appointment.reason}
            </p>
            {appointment.notes && (
              <p className="text-xs text-[var(--color-mutedText)] mt-1">
                {appointment.notes}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TodaySchedule;