import React from 'react';

const Tasks = ({ tasks }) => (
  <div className="h-full p-4">
    <div className="space-y-4">
      {tasks?.map((task, index) => (
        <div key={index} className="flex items-center justify-between mb-4 last:mb-0">
          <p>{task.task}</p>
          <div className="flex items-center space-x-2">
            <span className="px-2 py-1 bg-[var(--color-highlight)] rounded-full text-xs">
              {task.due}
            </span>
            <button className="px-3 py-1 text-sm rounded"
                    style={{
                      backgroundColor: 'var(--color-primary)',
                      color: 'var(--color-buttonText)'
                    }}>
              Action
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Tasks;