import React, { useState, useEffect } from 'react';
import { AutoWidgetWrapper } from '../common';
import { getRevenueBreakdownData } from '../../data/dataServices';

export default function RevenueBreakdown() {
  const [revenueData, setRevenueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [minRevenue, setMinRevenue] = useState(0);

  useEffect(() => {
    async function fetchRevenueData() {
      try {
        const data = await getRevenueBreakdownData();
        setRevenueData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
    fetchRevenueData();
  }, []);

  const filteredRevenueData = revenueData.filter(item => 
    item.revenueReceived >= minRevenue
  );

  const controls = (
    <label className="flex items-center space-x-2 text-sm">
      <span>Min Revenue:</span>
      <input 
        type="number" 
        value={minRevenue} 
        onChange={(e) => setMinRevenue(Number(e.target.value))} 
        className="p-1 w-24 border border-[var(--color-secondary)] rounded bg-[var(--color-background)]"
      />
    </label>
  );

  return (
    <AutoWidgetWrapper 
      title="Revenue Breakdown" 
      className="widget-list"
      loading={loading}
      error={error}
      controls={controls}
    >
      <div className="space-y-4">
        {filteredRevenueData.map((item, index) => (
          <div key={index} className="widget-data-row hover:bg-[var(--color-highlight)] transition">
            <div>
              <h4 className="text-lg font-medium">{item.payor}</h4>
              <p className="text-sm text-[var(--color-secondary)]">
                Revenue Received: ${item.revenueReceived.toLocaleString()}
              </p>
              <p className="text-sm text-[var(--color-secondary)]">
                Claims: {item.claims}
              </p>
            </div>
            {item.claimDenialRate > 5 && (
              <span className="px-2 py-1 bg-red-100 text-red-800 rounded-full text-xs">
                High Denial Rate
              </span>
            )}
          </div>
        ))}
      </div>
    </AutoWidgetWrapper>
  );
};
