import React from 'react';

const DeniedClaims = ({ claims }) => (
  <div className="h-full p-4">
    <div className="space-y-4">
      {claims?.map((claim, index) => (
        <div key={index} className="border-b last:border-b-0 pb-3 last:pb-0"
             style={{ borderColor: 'var(--color-widgetBorder)' }}>
          <p className="font-semibold">{claim.patient}</p>
          <p>{claim.date} - {claim.amount}</p>
          <p className="text-sm text-[var(--color-urgent)]">{claim.reason}</p>
        </div>
      ))}
    </div>
  </div>
);

export default DeniedClaims;