import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AIProvider } from '../context/AIContext'; // AI context provider
import { ThemeProvider } from '../context/ThemeContext'; // For theme management
import { GlobalUIProvider } from '../context/GlobalUIContext'; // Global UI context

// Create a query client for managing react-query state
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      retry: 1,
      refetchOnWindowFocus: false, // Optional: Prevent refetching on window focus
    },
  },
});

export function AppProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider> {/* Ensure theme context is applied */}
        <GlobalUIProvider> {/* Manage global UI state across the app */}
          <AIProvider> {/* Wrap components needing AI functionality */}
            {children}
          </AIProvider>
        </GlobalUIProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
