import React from 'react';

export default function Schedule({ appointments }) {
  return (
    <div className="flex-1 p-6 min-h-screen overflow-auto" style={{ backgroundColor: 'var(--color-background)' }}>
      <h1 className="text-2xl font-bold mb-6" style={{ color: 'var(--color-text)' }}>Today's Schedule</h1>
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="text-left" style={{ backgroundColor: 'var(--color-primary)', color: 'white' }}>
              <th className="p-4">Time</th>
              <th className="p-4">Patient</th>
              <th className="p-4">Age</th>
              <th className="p-4">Reason</th>
              <th className="p-4">Notes</th>
            </tr>
          </thead>
          <tbody>
            {appointments?.map((appointment) => (
              <tr 
                key={appointment.id} 
                className="border-b"
                style={{ borderColor: 'var(--color-widgetBorder)' }}
              >
                <td className="p-4">{appointment.time}</td>
                <td className="p-4">
                  <div className="flex items-center space-x-3">
                    <img 
                      src={appointment.patient.image} 
                      alt={`${appointment.patient.firstName} ${appointment.patient.lastName}`} 
                      className="w-8 h-8 rounded-full"
                    />
                    <span>{appointment.patient.lastName}, {appointment.patient.firstName}</span>
                  </div>
                </td>
                <td className="p-4">{appointment.patient.age}</td>
                <td className="p-4">
                  <span 
                    className="px-3 py-1 rounded-full text-sm"
                    style={{
                      backgroundColor: appointment.reason.includes('Follow-up')
                        ? 'var(--color-primary)'
                        : 'var(--color-accent)',
                      color: 'white'
                    }}
                  >
                    {appointment.reason}
                  </span>
                </td>
                <td className="p-4">{appointment.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}