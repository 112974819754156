import React, { useRef, useContext, useState } from 'react';
import { Settings, Brain, GripHorizontal } from 'lucide-react';
import { useAI } from '../../context/AIContext';
import { userData } from '../../data/mockDatabase';

const AutoWidgetWrapper = ({ 
  children, 
  title,
  onSettingsClick,
}) => {
  const contentRef = useRef(null); // Reference to widget content
  const { openAI } = useAI(); // AI context to trigger AI functions
  const { currentUser } = userData; // Mocked user data for metadata
  const [widgetInfo, setWidgetInfo] = useState(null); // Optionally store widget data

  // Function to scrape widget data on AI trigger
  const scrapeWidgetData = () => {
    const element = contentRef.current;
    if (!element) return null;

    // Find all data-* elements
    const dataElements = element.querySelectorAll('[data-*]');
    const dataMap = {};
    dataElements.forEach(el => {
      Object.keys(el.dataset).forEach(key => {
        try {
          dataMap[key] = JSON.parse(el.dataset[key]); // Parse JSON if possible
        } catch {
          dataMap[key] = el.dataset[key]; // Fallback to raw data if JSON parsing fails
        }
      });
    });

    // Clean text content excluding scripts and styles
    const getCleanText = (el) => {
      const clone = el.cloneNode(true);
      const scripts = clone.querySelectorAll('script, style');
      scripts.forEach(script => script.remove());
      return clone.textContent || clone.innerText || '';
    };

    // Count visible elements in the widget
    const visibleElements = element.querySelectorAll('*:not(script):not(style)');
    
    // Extract table data if present
    const tables = Array.from(element.querySelectorAll('table')).map(table => {
      return {
        headers: Array.from(table.querySelectorAll('th')).map(th => th.textContent),
        rows: Array.from(table.querySelectorAll('tr')).map(tr => 
          Array.from(tr.querySelectorAll('td')).map(td => td.textContent)
        )
      };
    });

    // Extract lists if present
    const lists = Array.from(element.querySelectorAll('ul, ol')).map(list => 
      Array.from(list.querySelectorAll('li')).map(li => li.textContent)
    );

    // Assemble all the scraped data
    return {
      widgetTitle: title,
      timestamp: new Date().toISOString(),
      content: {
        text: getCleanText(element),
        structuredData: dataMap,
        tables,
        lists,
        metrics: {
          elementCount: visibleElements.length,
          textLength: getCleanText(element).length,
          hasTable: tables.length > 0,
          hasLists: lists.length > 0
        }
      },
      metadata: {
        user: {
          id: currentUser.id,
          username: currentUser.username,
          role: currentUser.role,
          department: currentUser.department
        },
        capture: {
          method: 'auto-scrape',
          version: '1.0',
          timestamp: new Date().toISOString()
        }
      }
    };
  };

  // Handle AI button click
  const handleAIClick = () => {
    const widgetData = scrapeWidgetData(); // Scrape widget data on click
    if (widgetData) {
      setWidgetInfo(widgetData); // Optionally store or display the data
      openAI(widgetData); // Trigger AI interaction
    }
  };

  return (
    <div className="h-full flex flex-col rounded-lg overflow-hidden shadow-sm bg-[var(--color-highlight)]">
      <div className="flex-shrink-0 flex items-center justify-between p-3 bg-[var(--color-primary)]">
        <h3 className="text-lg font-semibold text-white">{title}</h3>
        <div className="flex items-center space-x-2">
          {/* AI button to trigger scraping and AI */}
          <button 
            onClick={handleAIClick}
            className="p-1 hover:bg-opacity-80 rounded transition-colors duration-200 text-white"
            title="Ask AI about this widget"
          >
            <Brain className="h-5 w-5" />
          </button>
          
          {/* Settings button */}
          <button 
            onClick={onSettingsClick}
            className="p-1 hover:bg-opacity-80 rounded transition-colors duration-200 text-white"
            title="Widget settings"
          >
            <Settings className="h-5 w-5" />
          </button>
          
          {/* Dragging handle */}
          <div 
            className="cursor-move text-white" 
            title="Drag to move"
          >
            <GripHorizontal className="h-5 w-5" />
          </div>
        </div>
      </div>
      
      <div className="flex-1 overflow-auto bg-[var(--color-widgetBackground)]" ref={contentRef}>
        {children} {/* Render widget content */}
      </div>

      {/* Optionally display the scraped widget data */}
      {widgetInfo && (
        <div className="widget-info p-2 bg-gray-100">
          <strong>Widget Data:</strong>
          <pre className="text-xs">{JSON.stringify(widgetInfo, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default AutoWidgetWrapper;
