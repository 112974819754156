import { 
  patients, 
  appointments, 
  messages, 
  practiceData, 
  doctorData, 
  revenueBreakdownData, 
  outstandingClaimsData 
} from './mockDatabase';

// Set the default data source directly
let currentDataSource = 'mock';

export const setDataSource = (newDataSource) => {
  currentDataSource = newDataSource;
};

export const getDataSource = () => {
  return currentDataSource;
};

const fetchData = async (endpoint) => {
  const response = await fetch(endpoint);
  return response.json();
};

export const getPatients = async () => {
  switch (currentDataSource) {
    case 'mock':
      return Promise.resolve(patients);
    case 'api':
      return fetchData('/api/patients');
    case 'fhir':
      return fetchData('/fhir/Patient');
    default:
      throw new Error('Invalid data source');
  }
};

export const getAppointments = async () => {
  switch (currentDataSource) {
    case 'mock':
      return Promise.resolve(appointments);
    case 'api':
      return fetchData('/api/appointments');
    case 'fhir':
      return fetchData('/fhir/Appointment');
    default:
      throw new Error('Invalid data source');
  }
};

export const getMessages = async () => {
  switch (currentDataSource) {
    case 'mock':
      return Promise.resolve(messages);
    case 'api':
      return fetchData('/api/messages');
    case 'fhir':
      return fetchData('/fhir/Communication');
    default:
      throw new Error('Invalid data source');
  }
};

export const getPracticeData = async () => {
  switch (currentDataSource) {
    case 'mock':
      return Promise.resolve(practiceData);
    case 'api':
      return fetchData('/api/practice-data');
    case 'fhir':
      return fetchData('/fhir/Organization');
    default:
      throw new Error('Invalid data source');
  }
};

export const getDoctorData = async () => {
  switch (currentDataSource) {
    case 'mock':
      return Promise.resolve(doctorData);
    case 'api':
      return fetchData('/api/doctor-data');
    case 'fhir':
      return fetchData('/fhir/Practitioner');
    default:
      throw new Error('Invalid data source');
  }
};

export const getRevenueBreakdownData = async () => {
  switch (currentDataSource) {
    case 'mock':
      return Promise.resolve(revenueBreakdownData);
    case 'api':
      return fetchData('/api/revenue-breakdown');
    case 'fhir':
      return fetchData('/fhir/Invoice');
    default:
      throw new Error('Invalid data source');
  }
};

export const getOutstandingClaimsData = async () => {
  switch (currentDataSource) {
    case 'mock':
      return Promise.resolve(outstandingClaimsData);
    case 'api':
      return fetchData('/api/outstanding-claims');
    case 'fhir':
      return fetchData('/fhir/Claim');
    default:
      throw new Error('Invalid data source');
  }
};
