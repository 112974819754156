import React from 'react';

const OpenCharts = ({ charts }) => (
  <div className="h-full p-4">
    <div className="space-y-4">
      {charts?.map((chart, index) => (
        <div key={index} className="border-b last:border-b-0 pb-3 last:pb-0"
             style={{ borderColor: 'var(--color-widgetBorder)' }}>
          <p className="font-semibold">{chart.patient}</p>
          <p>{chart.reason}</p>
          <p className="text-sm text-[var(--color-secondary)]">
            Last updated: {chart.lastUpdated}
          </p>
        </div>
      ))}
    </div>
  </div>
);

export default OpenCharts;