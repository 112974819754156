import React from 'react';
import { DollarSign, UserCheck, ClipboardList, FileText } from 'lucide-react';
import { AutoWidgetWrapper } from '../common';

const MetricsOverview = () => {
  const metrics = [
    {
      icon: <DollarSign className="h-6 w-6" />,
      title: 'Total Revenue',
      value: '$576,432',
      change: '+6.5%'
    },
    {
      icon: <UserCheck className="h-6 w-6" />,
      title: 'Active Patients',
      value: '2,897',
      change: '+2.1%'
    },
    {
      icon: <ClipboardList className="h-6 w-6" />,
      title: 'Open Claims',
      value: '147',
      change: '-3.2%'
    },
    {
      icon: <FileText className="h-6 w-6" />,
      title: 'Pending Reviews',
      value: '28',
      change: '+0.8%'
    }
  ];

  return (
    <AutoWidgetWrapper title="Metrics Overview" className="widget-metrics">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {metrics.map((metric, index) => (
          <div 
            key={index}
            className="widget-interactive p-6 rounded-lg flex items-start space-x-4"
          >
            <div className="p-3 rounded-full bg-[var(--color-primary)] text-white">
              {metric.icon}
            </div>
            <div>
              <h3 className="text-sm font-medium text-[var(--color-secondary)]">
                {metric.title}
              </h3>
              <div className="mt-1">
                <p className="text-2xl font-semibold">{metric.value}</p>
                <p className={`text-sm ${
                  metric.change.startsWith('+') 
                    ? 'text-green-600' 
                    : 'text-red-600'
                }`}>
                  {metric.change}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </AutoWidgetWrapper>
  );
};

export default MetricsOverview;
