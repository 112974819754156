import React, { useState, useMemo } from 'react';
import { ChevronUp, ChevronDown, FileText, UserPlus, Search } from 'lucide-react';
import { AutoWidgetWrapper } from '../common';
import { useGlobalUI, ActionTypes } from '../../context/GlobalUIContext';

const PatientList = ({ patients = [] }) => {
  const [sortConfig, setSortConfig] = useState({ key: 'lastName', direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  const { dispatch } = useGlobalUI();

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' 
        ? <ChevronUp className="inline h-4 w-4 ml-1" />
        : <ChevronDown className="inline h-4 w-4 ml-1" />;
    }
    return null;
  };

  const sortedPatients = useMemo(() => {
    if (!patients) return [];
    let filteredPatients = [...patients];

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filteredPatients = filteredPatients.filter(patient => 
        patient.lastName.toLowerCase().includes(searchLower) ||
        patient.firstName.toLowerCase().includes(searchLower) ||
        patient.email?.toLowerCase().includes(searchLower) ||
        patient.phoneNumber?.includes(searchTerm)
      );
    }

    return filteredPatients.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'age') {
        aValue = calculateAge(a.dateOfBirth);
        bValue = calculateAge(b.dateOfBirth);
      } else if (sortConfig.key === 'dateOfBirth') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [patients, sortConfig, searchTerm]);

  const handleAction = (actionType, patient) => {
    dispatch({
      type: ActionTypes.ADD_NOTIFICATION,
      payload: {
        id: Date.now(),
        message: actionType === 'chart' 
          ? `Opening chart for ${patient.firstName} ${patient.lastName}` 
          : `Scheduling appointment for ${patient.firstName} ${patient.lastName}`,
        type: 'info'
      }
    });
  };

  const renderPatient = (patient, index, style) => (
    <div style={style}>
      <div className="grid grid-cols-6 gap-4 px-4 py-2 border-b hover:bg-[var(--color-highlight)] transition-colors duration-200">
        <div className="flex items-center">
          {patient.id}
        </div>
        <div className="flex items-center space-x-2">
          <img 
            src={patient.image} 
            alt={`${patient.firstName} ${patient.lastName}`} 
            className="w-8 h-8 rounded-full"
          />
          <span>{patient.lastName}, {patient.firstName}</span>
        </div>
        <div className="flex items-center">
          {calculateAge(patient.dateOfBirth)}
        </div>
        <div className="flex items-center">
          {patient.gender}
        </div>
        <div className="flex items-center">
          ${patient.accountStatus?.balance?.toFixed(2) || '0.00'}
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => handleAction('chart', patient)}
            className="p-2 rounded-lg transition-colors duration-200"
            style={{ 
              backgroundColor: 'var(--color-primary)',
              color: 'var(--color-buttonText)'
            }}
          >
            <FileText className="h-4 w-4" />
          </button>
          <button
            onClick={() => handleAction('schedule', patient)}
            className="p-2 rounded-lg transition-colors duration-200"
            style={{ 
              backgroundColor: 'var(--color-accent)',
              color: 'var(--color-buttonText)'
            }}
          >
            <UserPlus className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );

  const headerContent = (
    <>
      <div className="p-4 border-b" style={{ borderColor: 'var(--color-widgetBorder)' }}>
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search patients..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded border"
            style={{ 
              backgroundColor: 'var(--color-searchBackground)',
              color: 'var(--color-searchText)',
              borderColor: 'var(--color-widgetBorder)'
            }}
          />
        </div>
      </div>
      <div 
        className="grid grid-cols-6 gap-4 px-4 py-2 font-medium"
        style={{ 
          backgroundColor: 'var(--color-primary)',
          color: 'white'
        }}
      >
        <div 
          onClick={() => handleSort("id")} 
          className="cursor-pointer flex items-center"
        >
          ID {getSortIndicator("id")}
        </div>
        <div 
          onClick={() => handleSort("lastName")} 
          className="cursor-pointer flex items-center"
        >
          Name {getSortIndicator("lastName")}
        </div>
        <div 
          onClick={() => handleSort("age")} 
          className="cursor-pointer flex items-center"
        >
          Age {getSortIndicator("age")}
        </div>
        <div 
          onClick={() => handleSort("gender")} 
          className="cursor-pointer flex items-center"
        >
          Gender {getSortIndicator("gender")}
        </div>
        <div 
          onClick={() => handleSort("balance")} 
          className="cursor-pointer flex items-center"
        >
          Balance {getSortIndicator("balance")}
        </div>
        <div>Actions</div>
      </div>
    </>
  );

  return (
    <AutoWidgetWrapper
      title="Patient List"
      virtualize={true}
      items={sortedPatients}
      itemHeight={60}
      renderItem={renderPatient}
      headerContent={headerContent}
    />
  );
};

export default PatientList;